import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { WebmasterStatisticFiltersResponse } from "@core/store/types/webmaster/statistic";

export default function statisticsGenerator (
  filters: WebmasterStatisticFiltersResponse,
  excludedFilters: BaseFiltersInput,
  groups: string[],
  isAgent: boolean,
  limit: number,
  offset: number,
  sort: Nullable<string | string[]>,
  order: Nullable<string>,
  payoutAnalyze: boolean
): ASTGeneratedOutput {
  const { vertical } = filters;
  const agent = isAgent ? [ {
    "webmaster": [
      "id",
      "intId",
      "login"
    ]
  },
  "moneyAgentCommission"
  ] : [];
  const dataSet = {
    NUTRA: [
      "items/countLeadsWhite",
      "items/conversionCleanCr",
      "items/conversionTransitEpc",
      "items/conversionLandingEpc",
      "items/countLeadsProcessing",
      "items/moneyWebmasterAwaiting",
      "items/conversionCleanApprove",
      "items/conversionApproveByCPL",
      "items/conversionApprove",
      "items/countLeadsFilteredSpam",
      "items/colorPayout/r",
      "items/colorPayout/g",
      "items/colorPayout/b",
      "items/countLeadsSpamByAdvertiser",
      "items/moneyDomonetizationWebmasterAccrued",
      "items/countDomonetizationClicks",
      "items/countDomonetizationLeadsCreated",
      "items/countDomonetizationLeadsAccepted",
      "items/countLeadsIntegrated",
      "items/countLandingClicks",
      "items/countTransitClicks",
      "items/conversionTransitToLanding"
    ],
    GAMBLING: [
      "items/countFirstDeposit",
      "items/countSecondDeposit",
      "items/countLeadsSpammed",
      "items/countFlowClicks",
      "items/moneyTotalDeposit",
      "items/moneyFirstDeposit",
      "items/moneySecondDeposit",
      "items/conversionCRSecondDepositToFirstDeposit",
      "items/conversionCRSecondDepositToUniques",
      "items/conversionCRFirstDepositToUniques",
      "items/conversionCRFirstDepositToLeads"
    ]
  };

  const params = {
    // @ts-ignore
    include: dataSet[vertical]
  };

  const gqlObject = {
    operation: "statisticsNew",

    fields: [
      {
        items: [
          ...agent,
          "countLeadsCreated",
          "countLeadsAccepted",
          "countLeadsHold",
          "moneyWebmasterAccrued",
          "countLeadsCancelled",
          "countFlowClicks",
          "countLandingUniques",
          "conversionCR",
          "conversionFlowEpc",
          {
            "offer": [
              "id",
              "intId",
              {
                "detail": [
                  "name"
                ]
              },
              "launchedAt"
            ]
          },
          {
            "bunch": [
              "transit",
              "landing"
            ]
          },
          {
            "flow": [
              "id",
              "name"
            ]
          },
          {
            "country": [
              "alpha2",
              "alpha3",
              "name",
              "id"
            ]
          },
          "externalWebmaster",
          "hours",
          "day",
          "week",
          "month",
          "year",
          "globalSource",
          "bonusCompetition",
          {
            "region": [
              "name",
              "id"
            ]
          },
          {
            "category": [
              "name",
              "id"
            ]
          },
          "currency",
          "utmCampaign",
          "utmContent",
          "utmMedium",
          "utmSource",
          "utmTerm",
          {
            withdraw: [
              "withdrawType"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      filters: {
        type: "StatisticsCriteriaFilters",
        value: filters
      },
      excludedFilters: {
        type: "StatisticsCriteriaFilters",
        value: excludedFilters
      },
      groups: {
        type: "[StatisticsGroupsEnum!]",
        value: groups
      },
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      sort: {
        type: "StatisticsSortEnum",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      },
      payoutAnalyze: {
        type: "Boolean!",
        value: payoutAnalyze
      }
    }
  };

  return Generator.query(gqlObject, params);
}
