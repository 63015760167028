import { offerEditWebmastersList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersList";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { OfferLandingsAddModalState, OfferLandingsAddModalInput } from "@core/store/types/admin/offer/OfferLandingsAddModal";
import { OfferLandingsAddModalService } from "@core/services/admin/offer/OfferLandingsAddModalService";
import {
  CREATE_OFFER_LANDING,
  UPLOAD_LANDING_FILE,
  EDIT_OFFER_LANDING,
  PROXY_OFFER_LANDING
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD,
  UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN,
  SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import { integrationCRMList } from "@core/store/modules/admin/lists/integrationCRMList";
import { flowRedirectMacrosList } from "@core/store/modules/admin/lists/flowRedirectMacrosList";
import { LandingTypeEnum } from "@core/store/types/admin/offer/enums/LandingTypeEnum";

const initialState = (): OfferLandingsAddModalState => {
  return {
    modal: {
      isActive: false,
      type: null
    },
    pageId: null,
    input: {
      fileId: null,
      name: null,
      offerId: null,
      isPublic: true,
      integrationId: null,
      designer: null,
      language: null,
      pageType: "landing",
      typeLanding: LandingTypeEnum.INTERIOR,
      path: null,
      options: [],
      webmasters: [],
      externalDomain: {
        hostname: null,
        isSecure: true,
        path: null,
        query: null
      },
      isTikTok: false
    },
    upload: {
      file: null
    }
  };
};

const state: () => OfferLandingsAddModalState = initialState;

const mutations: MutationTree<OfferLandingsAddModalState> = {
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  },

  [SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID] (state, pageId) {
    state.pageId = pageId;
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT] (state, input) {
    state.input = { ...state.input, ...input };
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN] (state, input) {
    state.input.externalDomain = { ...state.input.externalDomain, ...input };
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD] (state, upload) {
    state.upload = { ...state.upload, ...upload };
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<OfferLandingsAddModalState, RootState> = {
  async [UPLOAD_LANDING_FILE] ({ commit }, file) {
    const fd = new FormData();
    fd.append("file", file);
    const { data: { fileId } } = await OfferLandingsAddModalService.uploadLandingFile(fd);
    
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT, { fileId });
  },

  async [PROXY_OFFER_LANDING] ({ state: { modal: { type } }, dispatch }) {
    if (type === "add") {
      await dispatch(CREATE_OFFER_LANDING);

    } else {
      await dispatch(EDIT_OFFER_LANDING);
    }
  },
  
  async [CREATE_OFFER_LANDING] ({ state: { input } }) {
    await OfferLandingsAddModalService.createPage(input as OfferLandingsAddModalInput);
  },
  
  async [EDIT_OFFER_LANDING] ({ state: { pageId, input } }) {
    await OfferLandingsAddModalService.updatePage(pageId as string, input as OfferLandingsAddModalInput);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT, payload);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN, payload);
  },

  [UPDATE_MODAL] ({ commit }, payload: OfferLandingsAddModalState["modal"]) {
    commit(UPDATE_MODAL, payload);
  },

  [UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD] ({ commit }, payload) {
    commit(UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD, payload);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit(`integrationCRMList/${ SET_EMPTY }`);
    commit(`landingsWebmastersList/${ SET_EMPTY }`);
  }
};

export const offerLandingsAddModal: Module<OfferLandingsAddModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    integrationCRMList,
    landingsWebmastersList: offerEditWebmastersList,
    flowRedirectMacrosList
  }
};
