import AuthenticationProvider from "@core/logic/auth/AuthenticationProvider";
import { AuthService } from "@core/logic/auth/service";
import {
  Login,
  Registration,
  ResetPassword,
  SendNewPassword,
  TelegramLogin
} from "@core/store/types/common/auth";
import { ProfileService } from "@core/services/webmaster/ProfileService";
import { ProfileService as AdvertiserProfileService } from "@core/services/advertiser/ProfileService";
import Vue from "../../../../../main";
import {
  ADMIN_LOGIN_AS_ADMINISTRATOR,
  ADMIN_LOGIN_AS_ADVERTISER,
  ADMIN_LOGIN_AS_WEBMASTER,
  ADMIN_LOGOUT_ROLE,
  AUTHENTICATION_LOGOUT,
  GET_FINGER_PRINT,
  GET_TOKEN,
  GET_USER_PERMISSIONS,
  GET_WHO_AM_I,
  LOGOUT_PROFILE_SESSION,
  RESET_PASSWORD,
  SEND_NEW_PASSWORD,
  UPDATE_PREFERRED_CURRENCY,
  UPDATE_PREFERRED_VERTICAL,
  UPDATE_TOKEN,
  USER_LOGIN_BY_PASSWORD,
  USER_LOGIN_BY_TELEGRAM,
  USER_LOGOUT,
  USER_REGISTRATION
} from "@core/store/action-constants";
import {
  LOGOUT,
  SAVE_USER,
  SET_TOKEN,
  SET_WHO_AM_I,
  SET_FINGER_PRINT
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import Cookies from "js-cookie";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { UserForm } from "@core/store/logic/webmaster/UserForm";
import { setPreferredVertical } from "@core/store/modules/common/helpers/setPreferredVertical";
import { UserForm as AdvertiserUserForm } from "@core/store/logic/advertiser/UserForm";
import { AuthenticationService } from "@core/services/common/AuthenticationService";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { AuthState, Token } from "@core/store/types/common/auth";
import { signUp } from "@core/store/modules/common/signup/signUp";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import momentjs from "moment";
import mb5 from "md5";
import { hasPermissions } from "@core/mixins/permissions";

const state: AuthState = {
  role: null,
  self: null,
  isConfirmed: null,
  isApproved: null,
  isBlocked: null,
  subType: null,
  preferredCurrency: null,
  preferredVertical: VerticalsEnum.NUTRA,
  availableCurrencies: [],
  isPartnerNetwork: null,
  isShowDomonetizationStatistics: null,
  showPasswordChangeNotification: null,
  nextPasswordChangeDate: null,
  isAcceptOfferDisclaimer: false,
  fingerPrint: localStorage.getItem("finger_print") || null,
  token: {
    refresh: Cookies.get("refresh_token") || null,
    access: null,
    refreshTokenTtl: 0,
    expiresIn: 0
  },
  userPermissions: null
};

const getters: GetterTree<AuthState, RootState> = {
  isUserBlocked: (state): AuthState["isBlocked"] => state.isBlocked,
  isAcceptOfferDisclaimer: (state): AuthState["isAcceptOfferDisclaimer"] => state.isAcceptOfferDisclaimer,
  isPartnerNetwork: (state): AuthState["isPartnerNetwork"] => state.isPartnerNetwork,
  isUserLogin: (state): boolean => state.token.refresh !== null,
  isTokenSet: (state): boolean => state.token.access !== null,
  isActive: (state): AuthState["isApproved"] => state.isConfirmed && (state.role !== "admin" && state.isApproved),
  asAdmin: (state): boolean => /*state.role !== "admin" && */state.self === false,
  isAgent: (state): boolean => state.subType === "AGENT",
  hasForcedPasswordChange: (state): boolean => momentjs().isAfter(momentjs(state.nextPasswordChangeDate)),
  permissions: (state): AuthState["userPermissions"] => state.token.access ? state.userPermissions : null
};

const mutations: MutationTree<AuthState> = {
  [SAVE_USER] (state, payload: AuthState): void {
    Cookies.set("refresh_token", payload.token.refresh as string, { expires: payload.token.refreshTokenTtl });
    
    state.token = {
      ...state.token,
      refresh: payload.token.refresh
    };
    state.role = payload.role;
    state.self = payload.self;
    state.isConfirmed = payload.isConfirmed;
    state.isApproved = state.role === "admin" ? true : payload.isApproved;
    state.isBlocked = payload.isBlocked;
    state.subType = payload.subType;
    state.preferredCurrency = payload.preferredCurrency;
    state.preferredVertical = payload.preferredVertical;
    state.showPasswordChangeNotification = payload.showPasswordChangeNotification;
    state.nextPasswordChangeDate = payload.nextPasswordChangeDate;
    state.isPartnerNetwork = payload.isPartnerNetwork;
    state.isShowDomonetizationStatistics = payload.isShowDomonetizationStatistics;
    state.availableCurrencies = payload.availableCurrencies;
  },
  
  [SET_TOKEN] (state, payload: Token): void {
    state.token = {
      ...state.token,
      access: payload.access,
      expiresIn: payload.expiresIn
    };
  },
  
  [LOGOUT] (state): void {
    Cookies.remove("refresh_token");
    Cookies.remove("login_id");

    state.token = {
      refresh: null,
      access: null,
      refreshTokenTtl: 0,
      expiresIn: 0
    };
    state.role = null;
    state.self = null;
    state.isConfirmed = null;
    state.isApproved = null;
    state.isBlocked = null;
    state.subType = null;
    state.preferredCurrency = null;
    state.preferredVertical = null;
    state.availableCurrencies = [];
    state.isPartnerNetwork = null;
    state.isShowDomonetizationStatistics = null;
    state.showPasswordChangeNotification = null;
    state.nextPasswordChangeDate = null;
  },
  
  [UPDATE_PREFERRED_CURRENCY] (state, payload: string): void {
    state.preferredCurrency = payload;
  },

  [UPDATE_PREFERRED_VERTICAL] (state): void {
    const gamblingOnly = hasPermissions("VERTICALS.GAMBLING", state.userPermissions);
    const allVerticals = hasPermissions("VERTICALS.ALL", state.userPermissions);
    const nutraOnly = hasPermissions("VERTICALS.NUTRA", state.userPermissions);

    const changedPreferredVertical = allVerticals || nutraOnly && gamblingOnly
      ? state.preferredVertical as VerticalsEnum : nutraOnly ? VerticalsEnum.NUTRA : VerticalsEnum.GAMBLING;

    state.preferredVertical = changedPreferredVertical;
    setPreferredVertical(changedPreferredVertical);
  },

  [SET_FINGER_PRINT] (state, fingerPrint: string): void {
    state.fingerPrint = fingerPrint;
  },
  
  [SET_WHO_AM_I] (state, payload): void {
    state = Object.assign(state, payload);
  },
  
  [GET_USER_PERMISSIONS] (state, userPermissions: []): void {
    state.userPermissions = userPermissions;
  }
};

const actions: ActionTree<AuthState, RootState> = {
  async [USER_LOGIN_BY_PASSWORD] (_, payload: Login): Promise<void> {
    await AuthenticationProvider.loginByPassword(payload);
  },
  
  async [USER_LOGIN_BY_TELEGRAM] ({ state: { fingerPrint } }, payload: TelegramLogin): Promise<void> {
    await AuthenticationProvider.loginByTelegram({ ...payload, fingerPrint });
  },
  
  async [USER_LOGOUT] ({ commit }): Promise<void> {
    commit(LOGOUT);

    if (!Vue.$route.name?.startsWith("auth:")) {
      await Vue.$router.push({ name: "auth:signIn" });
    }
  },

  async [AUTHENTICATION_LOGOUT] (): Promise<void> {
    await AuthenticationProvider.logoutUser();
  },
  
  async [RESET_PASSWORD] (_, params: ResetPassword): Promise<void> {
    await AuthService.resetPassword(params);
  },

  [UPDATE_TOKEN] ({ commit }, token: Token): void {
    commit(SET_TOKEN, token);
  },
  
  async [SEND_NEW_PASSWORD] (_, params: SendNewPassword): Promise<boolean> {
    const { data } = await AuthService.sendNewPassword(params);
    return data;
  },
  
  async [ADMIN_LOGIN_AS_ADMINISTRATOR] ({ state }, adminId: string): Promise<void> {
    if (state.role === "admin") {
      await AuthenticationProvider.loginAsAdministrator(adminId);
      await Vue.$router.push({ name: "main" });
    }
  },
  
  async [ADMIN_LOGIN_AS_WEBMASTER] ({ state }, webmasterId: string): Promise<void> {
    if (state.role === "admin") {
      await AuthenticationProvider.loginAsWebmaster(webmasterId);
      await Vue.$router.push({ name: "main" });
    }
  },
  
  async [ADMIN_LOGIN_AS_ADVERTISER] ({ state }, advertiserId: string): Promise<void> {
    if (state.role === "admin") {
      await AuthenticationProvider.loginAsAdvertiser(advertiserId);
      await Vue.$router.push({ name: "main" });
    }
  },
  
  async [ADMIN_LOGOUT_ROLE] (): Promise<void> {
    await AuthenticationProvider.returnToAdminProfile();
    await Vue.$router.push({ name: "main" });
  },
  
  async [USER_REGISTRATION] ({ state: { fingerPrint } }, data: Registration): Promise<any> {
    await AuthenticationProvider.loginByRegistration({ ...data, fingerPrint });
  },
  
  [GET_TOKEN] ({ state }): Promise<AuthenticationProvider> | undefined {
    const refresh = Cookies.get("refresh_token");
    const loginId = Cookies.get("login_id");
    const fingerPrint = state.fingerPrint;
    if (refresh && fingerPrint && loginId) {
      const headerOptions = mb5(`${ loginId + refresh }`);
      return AuthenticationProvider.loginByToken(refresh, fingerPrint, headerOptions);
    }
  },

  async [GET_WHO_AM_I] ({ commit, state: { role } }): Promise<void> {
    const whoAmI = await AuthenticationProvider.whoAmI(role);

    commit(SET_WHO_AM_I, whoAmI);
    commit(UPDATE_PREFERRED_VERTICAL);
  },

  async [GET_FINGER_PRINT] ({ commit }): Promise<void> {
    const fP = await FingerprintJS.load();
    const result = await fP.get();
    localStorage.setItem("finger_print", result.visitorId as string);
    commit(SET_FINGER_PRINT, result.visitorId);
  },
  
  async [GET_USER_PERMISSIONS] ({ state, commit }): Promise<void> {
    if (state.role === "admin") {
      const { userPermissions } = await AuthenticationProvider.getUserPermissions();
      commit(GET_USER_PERMISSIONS, userPermissions);
      commit(UPDATE_PREFERRED_VERTICAL);
    }
  },
  
  async [UPDATE_PREFERRED_CURRENCY] ({ commit, state, rootState }, preferredCurrency: string): Promise<void> {
    if (state.role === "advertiser") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { systemVertical, ...input } = new AdvertiserUserForm(rootState.advertiser.profile.user).isForm();
      // @ts-ignore
      await AdvertiserProfileService.editProfile({ ...input, preferredCurrency });
    } else {
      const input = new UserForm(rootState.webmaster.profile.user).isForm();
      await ProfileService.editProfile({ ...input, preferredCurrency });
    }
    commit(UPDATE_PREFERRED_CURRENCY, preferredCurrency);
  },

  async [LOGOUT_PROFILE_SESSION] (_, loginId: string) {
    await AuthenticationService.logoutProfileSession(loginId);
  }
};

export const auth: Module<AuthState, RootState> = {
  state,
  getters,
  actions,
  mutations,
  modules: {
    signUp
  }
};
