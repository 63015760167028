import CRM from "@/router/admin/CRM";
import domains from "@/router/admin/domains";
import efficiency from "@/router/admin/efficiency";
import finances from "@/router/admin/finance";
import integrations from "@/router/admin/integrations";
import offers from "@/router/admin/offers";
import capacity from "@/router/admin/capacity";
import payments from "@/router/admin/payments";
import roles from "@/router/admin/roles";
import audit from "@/router/admin/audit";
import competition from "@/router/admin/competition";
import statistic from "@/router/admin/statistic";
import users from "@/router/admin/users";
import dashboardAffiliate from "@/router/admin/dashboardAffiliate";
import { RouteConfig } from "vue-router/types/router";

import Full from "@/views/Full.vue";
import complaints from "@/router/admin/complaints";
import changeManager from "@/router/admin/changeManager";

export const admin: RouteConfig = {
  path: "/admin",
  name: "admin",
  component: Full,
  children: [
    ...integrations,
    ...finances,
    ...users,
    ...offers,
    ...complaints,
    ...capacity,
    ...payments,
    ...statistic,
    ...roles,
    ...audit,
    ...competition,
    ...domains,
    ...dashboardAffiliate,
    ...efficiency,
    ...CRM,
    ...changeManager
  ]
};
