import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import getSelectedOffersQuery from "./graphql/getSelectedOffersQuery.graphql";
import { OffersFilters } from "@core/store/types/webmaster/offers";
export class StatisticsRatingService extends Service {
  public static async getSelectedOffers (
    filters: OffersFilters,
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    try {
      const { data: { offers } } = await this.api.get("", {
        params: {
          query: getSelectedOffersQuery,
          variables: {
            filters,
            limit,
            offset
          }
        }
      });
      return offers;
    } catch (e) {
      throw e;
    }
  }
}