<template>
    <b-dropdown
        :mobile-modal="false"
        :triggers="['hover']"
        aria-role="list"
        class="competitionInfo">
        <template #trigger>
            <section class="dropdown-wrapper">
                <img
                    alt="avatar"
                    class="mr-2"
                    style="width: 38px; height: 38px"
                    src="@/assets/Competition/Raffle/avatar.svg">

                <div>
                    <h2 class="has-text-black">
                        Spring Race
                    </h2>
                    <div class="flex">
                        <strong>
                            {{ currentCompetition.balance }}
                        </strong>
                        <img
                            alt="coin"
                            class="ml-1"
                            src="@/assets/Competition/Raffle/noto_coin.svg">
                    </div>
                </div>
            </section>
        </template>

        <div class="competitionInfo-wrapper has-text-white">
            <div class="competitionInfo-body">
                <h2>{{ $t("webmaster.dashboard.competition.dropdown.title") }}</h2>
                <div class="is-align-items-center my-1">
                    <span class="is-size-5">
                        {{ currentCompetition.balance }}
                    </span>
                    <img
                        alt="coin"
                        class="ml-1"
                        src="@/assets/Competition/Raffle/noto_coin.svg">
                </div>
                <p
                    class="is-size-7"
                    style="max-width: 250px">
                    {{ $t("webmaster.dashboard.competition.dropdown.description") }}
                </p>

                <router-link
                    :to="{
                        name: 'webmaster:offers',
                        query: {
                            filters: encodeFilters({ inCompetition: true }),
                            backLink: $route.fullPath
                        }
                    }">
                    <p class="is-size-7 mt-2 is-underlined has-text-white">
                        {{ $t("webmaster.dashboard.competition.dropdown.competitors") }}
                    </p>
                </router-link>
            </div>
        </div>
    </b-dropdown>
</template>

<script>
  import { mapState } from "vuex";
  import { GET_CURRENT_COMPETITION } from "@core/store/action-constants";
  import { encodeFilters } from "@core/filters";

  export default {
    name: "CompetitionDropdown",

    computed: {
      ...mapState("competition", {
        currentCompetition: ({ currentCompetition }) => currentCompetition
      }),

      isLoading () {
        return this.$wait(`competition/${ GET_CURRENT_COMPETITION }`);
      }
    },

    methods: { encodeFilters }
  };
</script>

<style lang="scss" scoped>
  .competitionInfo {
    $self: &;
    $border-radius: 10px;
    $inner-indent: 10;

    ::v-deep {
      .dropdown-content {
        padding: 0;
        border-radius: $border-radius;
      }

      .dropdown-menu {
        position: absolute;
        left: -50px;

        padding-top: #{ $inner-indent }px;
        border-radius: $border-radius;

        &::before {
          content: "";

          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;

          width: 100%;
          height: calc(100% - #{ $inner-indent }px);

          border-radius: $border-radius;
          box-shadow: 0 4px 4px 0 #25062C40;
        }
      }
    }

    &-wrapper {
      position: relative;

      overflow: hidden;
      border-radius: $border-radius;

      #{ $self }-body {
        width: 350px;

        padding: 20px;
        border-radius: $border-radius;
        background-image: url("~@/assets/Competition/Raffle/BG-dropdown.png");
        background-position: center;
        background-size: cover;
      }
    }
  }

  .dropdown-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 2rem;
  }
</style>
