<template>
    <v-select
        ref="vSelect"
        :append-to-body="appendToBody && !multiple"
        :autocomplete="autocomplete"
        :calculate-position="calculatePosition"
        :class="['control', inputClass, {
            'fixed': !multiple,
            'not-empty': value != null && value.length !== 0, 'fix-height': isFixHeight
        }]"
        :clearable="isClearable"
        :close-on-select="!multiple"
        :create-option="createOption"
        :disabled="disabled || isInitialDataLoading"
        :filter-by="_filterBy"
        :filterable="filterable"
        :label="field"
        :loading="loading || isInitialDataLoading"
        :multiple="multiple"
        :no-drop="noDrop"
        :options="filteredOptions"
        :placeholder="placeholder"
        :taggable="taggable"
        :value="computedValue"
        :selectable="selectable"
        @close="close"
        @input="select($event)"
        @open="open"
        @search="debouncedSearch"
        @option:selecting="selectOption"
        @search:blur="selectingOnBlur"
        @keyup.native.space="selectingOnSpace">
        <template
            v-if="hasNextPage || localLoading"
            #list-footer>
            <div class="has-text-centered">
                <InlineLoader
                    ref="load"
                    active
                    size="is-large"></InlineLoader>
            </div>
        </template>

        <template #search="{ attributes, events }">
            <slot
                :attributes="attributes"
                :computedValue="computedValue"
                :cssClass="[{ 'mt-0': !computedValue },'vs__search']"
                :events="events"
                :required="required"
                name="search">
                <input
                    ref="input"
                    v-bind="attributes"
                    :class="[{ 'mt-0': !computedValue },'vs__search']"
                    :required="required && !computedValue"
                    :style="{
                        cursor: disabled ? 'not-allowed' : !searchable ? 'pointer' : 'text',
                        'caret-color': !searchable ? 'transparent' : 'auto'
                    }"
                    v-on="events">
            </slot>
        </template>

        <template #option="option">
            <slot :option="option">
                <div class="media">
                    <div class="media-content">
                        <slot
                            :field="field"
                            :option="option"
                            name="text">
                            <p :class="[{ 'marquee': marquee && ((field ? option[field] : option) || option.id).length > maxChars }, 'nowrap']">
                                {{ (field ? option[field] : option) || option.id }}
                            </p>
                        </slot>
                    </div>
                </div>
            </slot>
        </template>

        <template #selected-option="option">
            <slot
                :option="option"
                name="selected-option">
                {{ (field ? option[field] : option) || option.id }}
            </slot>
        </template>
        
        <template #selected-option-container="options">
            <slot
                name="option-container"
                v-bind="options">
            </slot>
        </template>

        <template #open-indicator="{ attributes }">
            <b-icon
                v-if="indicator"
                v-bind="attributes"
                icon="angle-down"
                pack="fas"
                size="is-small">
            </b-icon>
            <span v-else></span>
        </template>

        <template #spinner="{ loading }">
            <div
                v-if="loading"
                class="vs__spinner"></div>
        </template>

        <template #no-options>
            <div></div>
        </template>
    </v-select>
</template>

<script>
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
  import _debounce from "lodash/debounce";
  import _isEmpty from "lodash/isEmpty";

  export default {
    name: "Select",

    extends: BaseSelect,

    components: {
      InlineLoader
    },

    created () {
      this.update();
    },

    mounted () {
      if (!this.searchable) {
        this.$refs.input.onkeydown = () => false;
      }
    },

    data () {
      return {
        offset: 0,
        label: "",
        items: [],
        values: {},
        rawData: null,
        isActive: false,
        localLoading: false
      };
    },

    beforeUpdate () {
      this.fillValues();
    },

    computed: {
      inputClass () {
        return this.disabled ? "input-disabled" : !this.searchable ? "input-none-searchable" : "input-searchable";
      },

      isFixHeight () {
        return this.value == null || this.value?.length === 0 || !this.multiple;
      },
      
      isInitialDataLoading () {
        return this.localLoading && !this.isActive;
      },

      hasNextPage () {
        return this.items.length < (this.rawData ? this.rawData.count : 0);
      },

      computedValue () {
        if (this.value != null) {
          if (typeof this.value === "object" && Object.keys(this.values).length !== 0) {
            return this.value.map(value => this.values[String(value)]);
          } else {
            return this.values[String(this.value)];
          }
        }

        return undefined;
      },

      maxChars () {
        const elementWidth = this.$refs.vSelect.$el.clientWidth;
        const charWidth = 8; // ширина символа в пикселях
        const maxChars = Math.floor(elementWidth / charWidth);

        document.documentElement.style.setProperty("--translate-element-width", `${ elementWidth - 30 }px`);
        return maxChars - 2;
      },

      debouncedSearch () {
        return _debounce(this.search, 400, { leading: false, trailing: true });
      },

      filteredOptions () {
        if (this.value != null && this.filterable) {
          if (typeof this.prop === "string") {
            if (this.multiple) {
              if (typeof this.value === "object") {
                return this.items.filter(option => this.value.every(value => String(option[this.prop] ?? option) !== String(value)));
              } else {
                return this.items.filter(option => this.value !== (option[this.prop] ?? option));
              }
            } else return this.items.filter(option => this.value !== option[this.prop] ?? option);
          } else {
            return this.items.filter(option => this.value.every(value => this.prop.every(prop => (option[prop] ?? option) !== value)));
          }
        } else {
          return this.items;
        }
      },

      observer () {
        return new IntersectionObserver(this.infiniteScroll, { threshold: 0.1 });
      },

      isClearable () {
        return this.loading || this.localLoading ? false : this.clearable;
      },

      isAbleToUpdate () {
        return this.label.length === 0 || this.label.length >= this.minRequiredLength;
      },

      locale () {
        return this.$i18n.locale;
      }
    },

    methods: {
      fillValues () {
        if (this.value != null) {
          if (typeof this.value === "object") {
            this.value.forEach(value => {
              value = String(value);

              if (!this.values[value]) {
                this.values = { ...this.values, [value]: this.items.find(item => String(item[this.prop]) === String(value)) };
              }
            });
          } else {
            const value = String(this.value);

            if (!this.values[value]) {
              this.values = { ...this.values, [value]: this.items.find(item => (item[this.prop] ?? item) === this.value) };
            }
          }
        }
      },

      select (event) {
        if (event) {
          // Select может возвращать массив если multiple=true иначе значение ключа из пропса prop
          if (/*typeof event === "object" &&*/ !Array.isArray(event)) {
            this.$emit("input", event[this.prop] ?? event);
          } else {
            this.$emit("input", !_isEmpty(event) ? event.map(_event => _event[this.prop] ?? _event) : this.hideNull ? event : null);
          }
          this.$emit("select", !_isEmpty(event) ? event : null);
        } else {
          this.$emit("input", this.hideNull ? event : null);
          this.$emit("select", null);
        }
      },

      selectingOnSpace () {
        if (this.selectOnSpace)
          this.$refs.vSelect.typeAheadSelect();
      },

      selectingOnBlur () {
        if (this.selectOnBlur)
          this.$refs.vSelect.typeAheadSelect();
      },

      async search (label) {
        this.label = label;

        if (this.isAbleToUpdate) {
          this.offset = 0;
          this.items = [];
          await this.update();

          const load = this.$refs.load?.$el || this.$refs.load;
          if (load) {
            this.observer.observe(load);
          }
        }
      },

      async infiniteScroll ([{
        isIntersecting,
        target
      }]) {
        if (isIntersecting && this.offset) {
          const ul = target.offsetParent;
          const scrollTop = target.offsetParent.scrollTop;
          await this.update(true);
          await this.$nextTick();
          ul.scrollTop = scrollTop;
        }
      },

      async open () {
        this.$emit("open");
        this.isActive = true;
        if (this.hasNextPage) {
          await this.$nextTick();

          const load = this.$refs.load?.$el || this.$refs.load;
          this.observer.observe(load);
        }
      },

      close () {
        this.isActive = false;

        this.observer.disconnect();
      },

      async update (isLoading) {
        const count = (this.rawData || {}).count || Infinity;

        if (this.getData) {
          if (count > this.offset && this.isAbleToUpdate) {
            this.localLoading = true;
            this.rawData = await this.getData(this.offset, this.label.trim());
            this.localLoading = false;

            if (this.rawData && this.rawData.items?.length > 0) {
              this.items = [...this.items, ...this.rawData.items];
              this.offset = this.items.length;
            }
          }
        } else if (this.getDataVuex) {
          if (count > this.offset && this.isAbleToUpdate) {
            this.localLoading = true;
            this.rawData = await this.getDataVuex(this.label.trim(), isLoading);
            this.localLoading = false;

            if (this.rawData && this.rawData.items?.length > 0) {
              this.items = this.rawData.items;
              this.offset = this.items.length;
            }
          }
        }
      },

      selectOption (selectedOption) {
        this.$emit("option:selecting", selectedOption);
      }
    },

    watch: {
      seed: {
        deep: true,
        handler () {
          this.offset = 0;
          this.items = [];

          this.update();
        }
      },
      locale: {
        handler () {
          this.offset = 0;
          this.items = [];
          this.values = {};

          this.update();
        }
      }
    }
  };
</script>

<style lang="scss">
    @import "~vue-select/src/scss/global/variables";

    .fix-height {
        .vs {
            &__dropdown-toggle {
                height: 36px;
                display: flex;
                align-items: center;
            }

            &__search {
                margin: 0;
            }
        }
    }

    // Не перемещать в ::v-deep, т.к. с appendToBody стили отвалятся
    .vs__dropdown-menu {
        display: block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%;
        max-width: 100%;
        max-height: 200px;
        overflow: auto;
        box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
        border-top-style: solid;
        border-radius: 5px;
        background-color: white;

        .vs__dropdown-option {
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            line-height: 1.5;
            display: block;
            padding: 0.375rem 1rem;
            color: #4a4a4a;
            white-space: nowrap;

            &--highlight {
                background: whitesmoke;
                color: #333;
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "~bulma/sass/utilities/controls";

    $button-padding: 0.9em;
    //noinspection SassScssUnresolvedVariable
    $button-padding-vertical: calc(#{$button-padding} - #{$control-border-width});
    $button-padding-horizontal: $button-padding;

    .control {
        &.input-disabled {
          ::v-deep {
            .vs__dropdown-toggle {
              cursor: not-allowed;
            }
          }
        }
        &.input-none-searchable {
          ::v-deep {
            .vs__dropdown-toggle {
              cursor: pointer;
            }
          }
        }
        &.input-searchable {
          ::v-deep {
            .vs__dropdown-toggle {
              cursor: text;
            }
          }
        }

        &::v-deep {
            .vs {
                $self: &;

                &__selected {
                    background-color: #fafafa;
                    border: 1px solid rgba(140, 140, 140, .26);

                    .country-block {
                        justify-content: space-between;
                        align-items: center;
                    }

                    .country-flag {
                        position: absolute;
                        display: flex;
                        align-items: center;

                        .flag-icon {
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }

                    .country-item {
                        margin-left: 1.5rem;
                    }
                }

                &__spinner {
                    font-size: 3px;
                    border: .9em solid hsl(0deg 0% 100% / 10%);
                    border-left-color: rgba(60,60,60,.45);
                    animation: vSelectSpinner .7s linear infinite;
                    margin-right: 5px;
                }

                &__search::placeholder,
                &__dropdown-toggle,
                &__dropdown-menu {
                    font-family: inherit;
                    border-radius: $radius;
                    padding: 6px 0;
                }

                &__search {
                    padding: 0 4px;

                    &::placeholder {
                        //noinspection SassScssResolvedByNameOnly
                        font-weight: $placeholder-font-weight;
                        //noinspection SassScssResolvedByNameOnly
                        color: $placeholder-color;
                    }

                    &::-webkit-input-placeholder {
                        //noinspection SassScssResolvedByNameOnly
                        font-weight: $placeholder-font-weight;
                        //noinspection SassScssResolvedByNameOnly
                        color: $placeholder-color;
                    }

                    &::-ms-input-placeholder {
                        //noinspection SassScssResolvedByNameOnly
                        font-weight: $placeholder-font-weight;
                        //noinspection SassScssResolvedByNameOnly
                        color: $placeholder-color;
                    }
                }

                &__clear,
                &__open-indicator {
                    fill: #dbdbdb;
                }
            }

            .vs__dropdown-toggle {
                border-color: #dbdbdb;
            }

            &:hover {
                .vs__dropdown-toggle {
                    //noinspection SassScssResolvedByNameOnly
                    border: 1px solid $light;
                }
            }

            .vs__selected-options {
                input {
                    font-size: 0.875rem;

                    &[disabled="disabled"] {
                        background-color: transparent;
                    }
                }
            }
            &.not-empty {
                .vs__selected-options {
                    margin-top: -4px;
                    padding: 0 4px;
                    min-height: 25px; // предотвращает уменьшение высоты селекта при открытии
                }
            }

            &.vs {
                &--open {
                    .vs__dropdown-toggle {
                        border-color: $primary;
                    }
                }

                &--disabled {
                    .vs__dropdown-toggle {
                        background-color: #f8f8f8 !important;
                        cursor: not-allowed;
                    }

                    .vs__actions {
                        * {
                            display: none;
                        }
                        .vs__spinner {
                            display: flex;
                        }
                    }
                }

                &--single {
                    .vs__selected {
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }

            &.is-opened-top {
                top: auto;
                bottom: 100%;
            }

            &.white {
                .vs__dropdown-toggle {
                    background-color: #fff;
                }
            }

            &.fixed {
                .vs__selected-options {
                    white-space: pre;
                    overflow: hidden;
                    flex-wrap: unset;
                }

                .vs__selected {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .marquee {
        @media (min-width: 800px) {
            &:hover {
                animation: marquee 5s linear infinite;
            }
            @keyframes marquee {
                0% {
                    transform: translateX(0%);
                }
                50% {
                    //noinspection CssInvalidFunction
                    transform: translateX(calc(-100% + var(--translate-element-width)));
                }
                100% {
                    transform: translateX(0%);
                }
            }
        }
    }
</style>
