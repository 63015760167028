import { LandingsHistoryListState } from "@core/store/types/admin/statistic/landingsHistory/landingsHistory";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  LandingHistoryDateDirection,
  LandingHistoryGroups
} from "@core/store/types/admin/statistic/landingsHistory/landingHistoryEnum";

import {
  SYNC_LANDINGS_HISTORY_FILTERS,
  GET_LANDINGS_HISTORY,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  SET_LANDINGS_HISTORY,
  UPDATE_EXCLUDED_FILTERS,
  UPDATE_SORTINGS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { LandingsHistoryService } from "@core/services/admin/statistic/landingsHistory/LandingsHistoryService";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { flowsFilters } from "@core/store/modules/common/statistic/common/flowsFilters";
import { datepicker } from "@core/helpers/datepicker";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";
import { pagination } from "@core/helpers/pagination";

const initialState = (): LandingsHistoryListState => {
  return {
    landingOperations: null,
    filters: {
      datepicker: datepicker(),
      dateDirection: LandingHistoryDateDirection.BY_EVENT,
      group: LandingHistoryGroups.DAY,
      offerId: null,
      landingId: null,
      pageType: null,
      pageId: null,
      designer: null
    },
    excludedFilters: {
      excludedOfferId: null
    },
    sort: null,
    order: null
  };
};

const state: () => LandingsHistoryListState = initialState;

const getters: GetterTree<LandingsHistoryListState, RootState> = {};

const mutations: MutationTree<LandingsHistoryListState> = {
  [SET_EMPTY] (state, target?: string) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  [UPDATE_SORTINGS] (state, { sort, order }) {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [UPDATE_FILTERS] (state, filters: LandingsHistoryListState["filters"]) {
    Object.assign(state.filters, state.filters, filters);
  },
  
  [UPDATE_EXCLUDED_FILTERS] (state, filters: LandingsHistoryListState["excludedFilters"]) {
    state.excludedFilters = { ...state.excludedFilters, ...filters };
  },
  
  [SET_LANDINGS_HISTORY] (state, items: LandingsHistoryListState["landingOperations"]) {
    state.landingOperations = items;
  }
};
const actions: ActionTree<LandingsHistoryListState, RootState> = {
  async [GET_LANDINGS_HISTORY] ({ commit, state }) {
    const { excludedFilters, filters: { datepicker, group, dateDirection, ...fields }, sort, order } = state;
    const { limit, offset } = pagination();
    
    const filters = { ...fields, ...datepicker };
    const preparedExcludedFilters = prepareExcludedFilters(excludedFilters);

    const { data: { landingOperations } } = await LandingsHistoryService
      .getLandingsHistory(filters, group, dateDirection, preparedExcludedFilters, sort, order, limit, offset);

    const { extras: { group: extrasGroup } } = landingOperations;
    landingOperations.extras.group = extrasGroup === "pageCreatedDay" ? "day" :
      extrasGroup === "pageCreatedWeek" ? "week" :
        extrasGroup === "pageCreatedMonth" ? "month" : extrasGroup;

    commit(SET_LANDINGS_HISTORY, landingOperations);
  },
  
  [UPDATE_FILTERS] ({ commit, dispatch }, filters: LandingsHistoryListState["filters"]) {
    dispatch(SYNC_LANDINGS_HISTORY_FILTERS, filters);
    commit(UPDATE_FILTERS, filters);
  },
  
  [UPDATE_EXCLUDED_FILTERS] ({ commit, dispatch }, filters: LandingsHistoryListState["excludedFilters"]) {
    dispatch(SYNC_LANDINGS_HISTORY_FILTERS, filters);
    commit(UPDATE_EXCLUDED_FILTERS, filters);
  },
  
  [SYNC_LANDINGS_HISTORY_FILTERS] ({ dispatch }, filters: LandingsHistoryListState["filters"]) {
    const baseFilters = prepareBaseFilters(filters);
    
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
  },

  [UPDATE_SORTINGS] ({ commit }, payload): void {
    commit(UPDATE_SORTINGS, payload);
  },

  [SET_EMPTY] ({ commit }, target?: string) {
    commit("SET_EMPTY", target);
    commit(`flowsFilters/${ SET_EMPTY }`);
  }
};

export const landingsHistory: Module< LandingsHistoryListState, RootState> = {
  namespaced: true,
  modules: {
    flowsFilters
  },
  state,
  mutations,
  getters,
  actions
};