import LInput from "@/components/Common/Select/LInput.vue";
import FilterWrapper from "@/components/Common/FilterWrapper.vue";

import { i18n, Loader, Sentry } from "@core/plugins";
import { intercom } from "@core/plugins";
import router from "@/router";
import store from "@/store";
import VueCompositionAPI from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";
import "./saas/saas.config";

import {
  GET_CURRENCIES,
  GET_TIMEZONES,
  GET_TOKEN,
  UPDATE_LOCALE,
  GET_COMPETITION_CURRENT,
  GET_FINGER_PRINT
} from "@core/store/action-constants";

import { SnackbarProgrammatic as Snackbar } from "buefy";
import Vue, { CreateElement, VNode } from "vue";
import { mapActions } from "vuex";
import App from "./App.vue";

import "@core/filters";
import "@core/mixins/global";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { excludedFiltersPlugin } from "@/stores/plugins/excludedFiltersPlugin";

Vue.use(Loader, { store });
Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin);

if (process.env.VUE_APP_SENTRY_ON === "true") {
  Sentry();
}

Vue.config.productionTip = false;

// global components
// @ts-ignore
Vue.component("LInput", LInput);
Vue.component("FilterWrapper", FilterWrapper);

const pinia = createPinia();
pinia.use(excludedFiltersPlugin);

const app = new Vue({
  store,
  router,
  pinia,
  i18n,
  ...intercom,
  render: (h: CreateElement): VNode => h(App),
  setup () {
    return {
      permissionsStore: usePermissions()
    };
  },
  async created () {
    const { $store, $route, $router } = this;
    const routerName = $route.name || "";
    const auth = $store.state.auth;
    const lang = localStorage.getItem("locale") || "en".toLocaleLowerCase().slice(0, 2);

    if (!auth.fingerPrint) {
      await this.GET_FINGER_PRINT();
    }
    this.UPDATE_LOCALE(lang);

    this.GET_TIMEZONES();
    this.GET_CURRENCIES();
    this.GET_COMPETITION_CURRENT();

    this.GET_TOKEN().then(() => {
      if (store.getters.isTokenSet) {
        // @ts-ignore
        this.permissionsStore.GET_USER_PERMISSIONS();

        if (store.getters.isActive) {
          if (routerName === "auth:signUpProfile") {
            return $router.push({ name: "dashboard" });
          }
        }

        if (auth.isConfirmed && !auth.isApproved) {
          if (routerName !== "auth:signUpProfile") {
            return $router.push({ name: "auth:signUpProfile" });
          }
        } else if (!auth.isConfirmed && !auth.isApproved) {
          if (routerName !== "mail:send") {
            return $router.push({ name: "mail:send" });
          }
        } else if (
          auth.isConfirmed && auth.isApproved &&
            $route.name?.startsWith("auth:")
        ) {
          return $router.push({ name: "dashboard" });
        }

        if (!auth.isConfirmed) {
          Snackbar.open({
            type: "is-warning",
            message: this.$t("common.mailConfirmation.unconfirmed.title") as string,
            indefinite: true,
            position: "is-bottom-left"
          });
        }
      } else {
        if (!$route.name?.startsWith("auth:")) {
          return $router.push({ name: "auth:signIn" });
        }
      }
    });
  },
  
  methods: {
    ...mapActions([
      GET_TOKEN,
      GET_FINGER_PRINT,
      UPDATE_LOCALE,
      GET_CURRENCIES,
      GET_COMPETITION_CURRENT,
      GET_TIMEZONES
    ])
  }
});

app.$mount("#app");

export default app;
