import Service from "@core/services/common/Service";
import { OffersFilters, OffersState } from "@core/store/types/admin/offers";
import { AxiosPromise } from "axios";
import offers from "./graphql/offers.generator";
import advertisersCapacityQuery from "@core/services/admin/offers/graphql/advertisersCapacity.generator";
import advertiserCapacityHistoryQuery from "@core/services/admin/offers/graphql/advertiserCapacityHistory.graphql";
import userOfferCapacityHistoryQuery from "@core/services/admin/offers/graphql/userOfferCapacityHistory.graphql";
import updateOfferWebmasterRequiredApprove from "@core/services/admin/offers/graphql/updateOfferWebmasterRequiredApprove.graphql";
import externalWebmastersCapacityAnalyseQuery from "@core/services/admin/offers/graphql/externalWebmastersCapacityAnalyse.generator";
import subWebmastersCapacityAnalyseQuery from "@core/services/admin/offers/graphql/subWebmastersCapacityAnalyse.generator";
import offerAdvertisersCapacityQuery from "@core/services/admin/offers/graphql/offerAdvertisersCapacity.generator";
import offerWebmastersCapacityQuery from "@core/services/admin/offers/graphql/offerWebmastersCapacity.generator";
import externalWebmasterCapacityQuery from "@core/services/admin/offers/graphql/externalWebmasterCapacity.generator";
import subWebmasterCapacityQuery from "@core/services/admin/offers/graphql/subWebmasterCapacity.generator";
import setCapacityQuery from "@core/services/admin/offers/graphql/setCapacity.graphql";
import {
  CapacityHistoryResponse,
  AdvertisersCapacityFilters,
  AdvertisersCapacityResponse,
  AdvertisersCapacityState,
  InputCapacity,
  OffersCapacityResponse,
  OffersCapacityState,
  UpdateCapacityResponse,
  WebmastersCapacityResponse,
  WebmastersCapacityState,
  CapacityAnalyseFiltersResponse,
  ExternalWebmastersCapacityResponse,
  CapacityAnalyseItems, InputApprove, UpdateApproveResponse
} from "@core/store/types/admin/offers/capacity";
import { capacityMapper } from "@core/services/admin/offers/capacityMapper";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";

export class OffersService extends Service {
  public static async getOffers (
    filters: OffersFilters,
    excludedFilters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"],
    isShowCommissions: boolean,
    showAdvertisers: boolean,
    showIndividualRates: boolean
  ): Promise<OffersState["data"]> {
    const { data: { offers: data } } = await this.api.get("", {
      params: offers(
        filters,
        prepareExcludedFilters(excludedFilters),
        limit,
        offset,
        sort,
        order,
        isShowCommissions,
        showAdvertisers,
        showIndividualRates
      )
    });
    return data;
  }
  
  public static async getAdvertisersCapacity (
    limit: number,
    offset: number,
    filters: AdvertisersCapacityFilters,
    sort: AdvertisersCapacityState["sort"],
    order: AdvertisersCapacityState["order"],
    isRequest: boolean,
    isCommon: boolean,
    isShowRedemption: boolean
  ): Promise<AdvertisersCapacityResponse> {
    try {
      const { data: { advertisersCapacity } } = await this.api.get("", {
        params: advertisersCapacityQuery(
          limit,
          offset,
          filters,
          sort,
          order,
          isRequest,
          isCommon,
          isShowRedemption
        )
      });
      
      return capacityMapper(advertisersCapacity);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static getAdvertiserCapacityHistory (
    limit: number,
    offset: number,
    userId: string | null
  ): AxiosPromise<CapacityHistoryResponse> {
    try {
      return this.api.get("", {
        params: {
          query: advertiserCapacityHistoryQuery,
          variables: {
            limit,
            offset,
            userId
          }
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getExternalWebmastersCapacityAnalyse (
    offerId: string,
    webmasterId: string,
    filters: CapacityAnalyseFiltersResponse,
    isShowRedemption: boolean
  ): Promise<CapacityAnalyseItems> {
    try {
      const { data: { capacityGraphByWebmaster } } = await this.api.get("", {
        params: externalWebmastersCapacityAnalyseQuery(
          offerId,
          webmasterId,
          filters,
          isShowRedemption
        )
      });

      return capacityGraphByWebmaster;
    }
    catch (e) {
      throw e;
    }
  }

  public static async getSubWebmastersCapacityAnalyse (
    offerId: string,
    webmasterId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { externalWebmasterId, withExternalWebmasters, ...filters }: CapacityAnalyseFiltersResponse,
    isShowRedemption: boolean
  ): Promise<CapacityAnalyseItems> {
    try {
      const { data: { capacityGraphBySubWebmaster } } = await this.api.get("", {
        params: subWebmastersCapacityAnalyseQuery(
          offerId,
          webmasterId,
          {
            subWebmasterId: externalWebmasterId,
            withSubWebmasters: withExternalWebmasters,
            ...filters
          },
          isShowRedemption
        )
      });

      return capacityGraphBySubWebmaster;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getOffersCapacity (
    limit: number,
    offset: number,
    advertiserId: string,
    filters: AdvertisersCapacityFilters,
    sort: OffersCapacityState["sort"],
    order: OffersCapacityState["order"],
    isRequest: boolean,
    isCommon: boolean,
    isShowRedemption: boolean
  ): Promise<OffersCapacityResponse> {
    try {
      const { data: { offerAdvertisersCapacity } } = await this.api.get("", {
        params: offerAdvertisersCapacityQuery(
          limit,
          offset,
          advertiserId,
          filters,
          sort,
          order,
          isRequest,
          isCommon,
          isShowRedemption
        )
      });
      
      return capacityMapper(offerAdvertisersCapacity);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getWebmastersCapacity (
    limit: number,
    offset: number,
    offerId: string,
    filters: AdvertisersCapacityFilters,
    sort: WebmastersCapacityState["sort"],
    order: WebmastersCapacityState["order"],
    isRequest: boolean,
    isIndividualRate: boolean,
    isShowRedemption: boolean,
    isShowCommission: boolean
  ): Promise<WebmastersCapacityResponse> {
    try {
      const { data: { offerWebmastersCapacity } } = await this.api.get("", {
        params: offerWebmastersCapacityQuery(
          limit,
          offset,
          offerId,
          filters,
          sort,
          order,
          isRequest,
          isIndividualRate,
          isShowRedemption,
          isShowCommission
        )
      });
      
      return capacityMapper(offerWebmastersCapacity);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getExternalWebmastersCapacity (
    limit: number,
    offset: number,
    webmasterId: string,
    offerId: string,
    sort: WebmastersCapacityState["sort"],
    order: WebmastersCapacityState["order"],
    filters: AdvertisersCapacityFilters,
    isIndividualRate: boolean,
    isShowRedemption: boolean,
    isShowCommission: boolean
  ): Promise<WebmastersCapacityResponse> {
    try {
      const { data: { externalWebmasterCapacity } } = await this.api.get("", {
        params: externalWebmasterCapacityQuery(
          limit,
          offset,
          webmasterId,
          offerId,
          sort,
          order,
          filters.isRequest ?? false,
          isIndividualRate,
          isShowRedemption,
          isShowCommission
        )
      });
      return capacityMapper(externalWebmasterCapacity);
    }
    catch (e) {
      throw e;
    }
  }

  public static async getSubWebmastersCapacity (
    limit: number,
    offset: number,
    agentId: string,
    offerId: string,
    sort: WebmastersCapacityState["sort"],
    order: WebmastersCapacityState["order"],
    filters: WebmastersCapacityState["filters"],
    isIndividualRate: boolean,
    isShowRedemption: boolean
  ): Promise<WebmastersCapacityResponse> {
    try {
      const { data: { subWebmastersCapacity } } = await this.api.get("", {
        params: subWebmasterCapacityQuery(
          limit,
          offset,
          agentId,
          offerId,
          filters,
          sort,
          order,
          isIndividualRate,
          isShowRedemption
        )
      });

      return capacityMapper(subWebmastersCapacity);
    }
    catch (e) {
      throw e;
    }
  }

  public static getUserOfferCapacityHistory (
    limit: number,
    offset: number,
    userId: string | null,
    offerId: string | null
  ): AxiosPromise<CapacityHistoryResponse> {
    try {
      return this.api.get("", {
        params: {
          query: userOfferCapacityHistoryQuery,
          variables: {
            limit,
            offset,
            userId,
            offerId
          }
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static updateCapacity (
    input: InputCapacity
  ): AxiosPromise<UpdateCapacityResponse> {
    try {
      return this.api.post("", {
        query: setCapacityQuery,
        variables: {
          input
        }
      });
    }
    catch (e) {
      throw e;
    }
  }

  public static updateApprove (
    input: InputApprove
  ): AxiosPromise<UpdateApproveResponse> {
    try {
      return this.api.post("", {
        query: updateOfferWebmasterRequiredApprove,
        variables: {
          input
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
}
