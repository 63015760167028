<template>
    <main id="app">
        <SplashScreen v-if="isSplashScreen && !hasForcedPasswordChange"></SplashScreen>
        <template v-else-if="!isSplashScreen && (!hasForcedPasswordChange || asAdmin)">
            <router-view></router-view>
            <UploadsList v-if="uploadFileId"></UploadsList>
            <NoAdBlock></NoAdBlock>
            <ReleaseUpdate></ReleaseUpdate>
        </template>
        <template v-if="hasAdmin && !asAdmin">
            <Component :is="componentInstanceAdminProfilePasswordModal"></Component>
            <Component
                v-if="showPasswordChangeNotification"
                :key="role"
                :is="componentInstancePlannedChangePasswordModal">
            </Component>
        </template>
    </main>
</template>

<script>
  import UploadsList from "@/components/Admin/NavigationBar/Uploads/UploadsList";
  import ReleaseUpdate from "@/components/Common/ReleaseUpdate";
  import SplashScreen from "@/components/Common/SplashScreen";
  import NoAdBlock from "@/components/Common/NoAdBlock";
  import { GET_TOKEN } from "@core/store/action-constants";
  import { mapGetters, mapState } from "vuex";
  import axios from "axios";

  export default {
    name: "App",
    components: {
      ReleaseUpdate,
      SplashScreen,
      UploadsList,
      NoAdBlock
    },
  
    created () {
      this.changeLocale();
    },

    computed: {
      ...mapState({
        showPasswordChangeNotification: ({ auth }) => auth.showPasswordChangeNotification,
        hasAdmin: ({ auth }) => auth.role === "admin",
        role: state => state.auth.role
      }),

      ...mapGetters(["hasForcedPasswordChange", "asAdmin"]),

      componentInstanceAdminProfilePasswordModal () {
        return () => import("@/components/Common/Admin/AdminProfilePasswordModal");
      },

      componentInstancePlannedChangePasswordModal () {
        return () => import("@/components/Common/Admin/PlannedChangePasswordModal");
      },

      ...mapState("uploads", {
        uploadFileId: state => state.uploadFileId
      }),

      version () {
        return process.env.VUE_APP_VERSION;
      },

      userPermissions () {
        return (this.$store || { getters: {} }).getters.permissions;
      },

      isSplashScreen () {
        const { $store: { state: { auth }, getters }, $route } = this;
        return (
          (auth.role == null || !getters.isTokenSet ||
            (getters.profile || {}).user == null ||
            this.role === "admin" && !this.userPermissions) &&
          !($route.name || "").startsWith("auth") ||
          ($route.name || "").startsWith("auth") &&
          (this.$wait(GET_TOKEN) || auth.isConfirmed &&
            auth.isApproved && ($route.name || "").startsWith("auth") &&
            auth.subType !== "SUBWEBMASTER")
        );
      }
    },
  
    methods: {
      changeLocale () {
        const i18n = this.$root.$i18n;

        axios.defaults.headers.common["Accept-Language"] = i18n.locale;
        document.querySelector("html").setAttribute("lang", i18n.locale);

        this.$buefy.config.setOptions({
          defaultMonthNames: i18n.t("common.months"),
          defaultDayNames: i18n.t("common.days.short")
        });
      }
    },
  
    watch: {
      "$root.$i18n.locale" () {
        this.changeLocale();
      }
    }
  };
</script>

<!--suppress CssInvalidFunction, SassScssUnresolvedVariable, noinspection CssUnknownTarget -->
<style lang="scss">
    // styles for aside menu
    $sidebar-width: $aside-width-widescreen;
    $sidebar-mobile-width: $aside-width-until-widescreen;

    // Import Bulma's core
    @import url("https://use.fontawesome.com/releases/v6.1.1/css/all.css");
    @import url("https://unicons.iconscout.com/release/v2.1.11/css/unicons.css");
    @import url("https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css");
    @import "~bulma/sass/utilities/_all";

    // Set your colors
    @import "scss/variables";

    // Setup $colors to use as bulma classes (e.g. 'is-twitter')
    $colors: (
        "white": ($white, $black, $white, $light-light-invert),
        "black": ($black, $white, $light-light, $light-light-invert),
        "light": ($light, $light-invert, $light-light, $light-light-invert),
        "light-button": ($light-light, $light-light-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert, $primary-light, $primary-light-invert),
        "info": ($info, $info-invert, $info-light, $info-light-invert),
        "success": ($success, $success-invert, $success-light, $success-light-invert),
        "warning": ($warning, $warning-invert, $warning-light, $warning-light-invert),
        "danger": ($danger, $danger-invert, $danger-light, $danger-light-invert),
        "purple": ($purple, $purple-invert, $purple-light, $purple-light-invert),
        "turquoise": ($turquoise, $turquoise-invert, $turquoise-light, $turquoise-light-invert),
        "smoke": ($smoke, $smoke-invert, $smoke-light, $smoke-light-invert)
    );

    // Links
    $input-focus-border-color: $primary;
    $input-arrow: $primary;

    // Import Bulma and Buefy styles
    @import "scss/theme-default";
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
    @import "scss/main";
</style>
