import Service from "@core/services/common/Service";
import {
  LandingsHistoryFiltersResponse, LandingsHistoryListState,
  ResponseLandings
} from "@core/store/types/admin/statistic/landingsHistory/landingsHistory";
import landingOperations from "@core/services/admin/statistic/landingsHistory/graphql/landingOperations.graphql";
import {
  LandingHistoryDateDirection,
  LandingHistoryGroups
} from "@core/store/types/admin/statistic/landingsHistory/landingHistoryEnum";
import { UnknownObject } from "@core/store/types/common";

export class LandingsHistoryService extends Service {
  public static getLandingsHistory (
    filters: LandingsHistoryFiltersResponse,
    group: LandingHistoryGroups,
    dateDirection: LandingHistoryDateDirection,
    excludedFilters: UnknownObject,
    sort: LandingsHistoryListState["sort"],
    order: LandingsHistoryListState["order"],
    limit: number,
    offset: number
  ): Promise<ResponseLandings> {
    try {
      return this.api.get("", {
        params: {
          query: landingOperations,
          variables: {
            filters,
            group,
            dateDirection,
            excludedFilters,
            sort,
            order,
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}