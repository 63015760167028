export enum DomonetizationGroupsEnum {
  MONTH = "MONTH",
  WEEK = "WEEK",
  DAY = "DAY",
  DOMONETIZATION_CATEGORIES = "DOMONETIZATION_CATEGORIES",
  DOMONETIZATION_WEBMASTER = "DOMONETIZATION_WEBMASTER",
  DOMONETIZATION_OFFER = "DOMONETIZATION_OFFER",
  OFFER = "OFFER",
  OFFER_CATEGORIES = "OFFER_CATEGORIES",
  DOMONETIZATION_MANAGER = "DOMONETIZATION_MANAGER"
}