import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { OffersFilters, OffersState } from "@core/store/types/admin/offers";

export default function offers (
  filters: OffersFilters,
  excludedFilters: OffersFilters,
  limit: number,
  offset: number,
  sort: OffersState["sort"],
  order: OffersState["order"],
  isShowCommissions: boolean,
  showAdvertisers: boolean,
  showIndividualRates: boolean
): ASTGeneratedOutput {
  const money = ["value","currency"];
  const exclude = [];
  const include = [];
  if (showAdvertisers) {
    include.push(
      "items/advertisers/items/writeOff/value",
      "items/advertisers/items/writeOff/currency",
      "items/advertisers/items/advertiser/id",
      "items/advertisers/items/advertiser/intId",
      "items/advertisers/items/advertiser/login"
    );
  }
  if (showAdvertisers && showIndividualRates) {
    include.push(
      "items/advertisers/items/individualRates/items/writeOff/value",
      "items/advertisers/items/individualRates/items/writeOff/currency"
    );
  }
  if (!isShowCommissions) {
    exclude.push(
      "items/minimalCommission/value",
      "items/averageCommission/value",
      "items/minimalIndividualRate/value",
      "items/maximalIndividualRate/value",
      "items/finance/webmasterReward/value",
      "items/minimalCommission/currency",
      "items/averageCommission/currency",
      "items/minimalIndividualRate/currency",
      "items/maximalIndividualRate/currency",
      "items/finance/webmasterReward/currency"
    );
  }
  const params = {
    include,
    exclude
  };

  const gqlObject = {
    operation: "offers",
    
    fields: [
      {
        items: [
          "id",
          "intId",
          "slug",
          "isLaunched",
          "createdAt",
          "type",
          "labels",
          "vertical",
          "plannedStopAt",
          "isNewCreated",
          {
            minimalIndividualRate: money
          },
          {
            maximalIndividualRate: money
          },
          {
            minimalCommission: money
          },
          {
            averageCommission: money
          },
          {
            landing: [
              "currency",
              "cost"
            ]
          },
          {
            logo: [
              "url"
            ]
          },
          {
            previewLogo: [
              "url"
            ]
          },
          {
            detail: [
              "name"
            ]
          },
          {
            countries: [
              "id",
              "name",
              "alpha2"
            ]
          },
          {
            categories: [
              "id",
              "name"
            ]
          },
          {
            finance: [
              "withdrawType",
              "subGoalType",
              {
                webmasterReward: money
              }
            ]
          }
        ]
      },
      "count"
    ],
    
    variables: {
      filters: {
        type: "OfferListCriteriaFilters",
        value: filters
      },
      excludedFilters: {
        type: "OfferListCriteriaFilters",
        value: excludedFilters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      sort: {
        type: "OfferListCriteriaSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }

  };

  return Generator.query(gqlObject, params);
}

