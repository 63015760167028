import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_SUBSECTION_CAPACITY,
  UPDATE_CAPACITY,
  UPDATE_SUBSECTION_REQUEST,
  UPDATE_SUBSECTIONS,
  UPDATE_SUB_FILTERS, UPDATE_APPROVE
} from "@core/store/action-constants";
import {
  SET_SUBSECTION_CAPACITY,
  SET_EMPTY,
  SUBSECTION_CAPACITY_DATA,
  SUBSECTION_CAPACITY_PAGINATION,
  UPDATE_PAGINATION,
  SET_EMPTY_DATA,
  UPDATE_SORTINGS,
  SUBSECTION_CAPACITY_SORT,
  SUBSECTION_CAPACITY_ORDER,
  UPDATE_FILTERS,
  SET_SUBSECTION_REQUEST,
  SET_PAYOUT,
  SET_INDIVIDUAL_RATE
} from "@core/store/mutation-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import {
  UpdateApproveDTO,
  UpdateCapacityDTO,
  WebmastersCapacityData,
  WebmastersCapacityItem,
  WebmastersCapacityState
} from "@core/store/types/admin/offers/capacity";
import Vue from "vue";
import _isEmpty from "lodash/isEmpty";
import { capacityAnalyse } from "@core/store/modules/admin/capacityAndBumps/modules/log/capacityAnalyse";
import { capacityRequest } from "@core/store/modules/admin/capacityAndBumps/modules/common/capacityRequest";
import { hasPermissions } from "@core/mixins/permissions";
import { payoutRequest } from "@core/store/modules/admin/capacityAndBumps/modules/common/payoutRequest";
import { bookingRequest } from "@core/store/modules/admin/capacityAndBumps/modules/common/bookingRequest";
import moment from "moment/moment";
import { cancelCapacityRequest } from "@core/store/modules/admin/capacityAndBumps/modules/common/cancelCapacityRequest";
import { guaranteeRequest } from "@core/store/modules/admin/capacityAndBumps/modules/common/guaranteeRequest";

class WebmastersCapacity implements Module<WebmastersCapacityState, RootState> {
  public namespaced: boolean;
  public state: WebmastersCapacityState;
  public modules: ModuleTree<RootState>;
  private readonly initialPagination = {
    page: 1,
    perPage: 20
  }

  public getters: GetterTree<WebmastersCapacityState, RootState> = {
    [SUBSECTION_CAPACITY_DATA]: (state) => state.data,
  
    [SUBSECTION_CAPACITY_PAGINATION]: state => state.pagination,
  
    [SUBSECTION_CAPACITY_SORT]: state => state.sort,
  
    [SUBSECTION_CAPACITY_ORDER]: state => state.order,
    
    isIndividualRate: (state, getters, rootState, rootGetters): boolean => hasPermissions(["STATISTICS.SHOW.WEBMASTER_RATE"], rootGetters.permissions)
  }

  public mutations: MutationTree<WebmastersCapacityState> = {
    [SET_SUBSECTION_CAPACITY]: (state, payload): void => {
      let { id, ...data } = payload;
      if (!_isEmpty(state.data[id])) {
        data = {
          ...data,
          count: data.count,
          items: state.data[id]?.items?.concat(data.items)
        };
  
        state.data[id] = {};
      }
      Vue.set(state.data, id, data);
    },
  
    [SET_SUBSECTION_REQUEST] (state, payload) {
      const elem = state.data[payload.offerId]?.items
        ?.find(({ id }) => id === payload.webmasterId) as WebmastersCapacityData;
      
      Vue.set(elem, payload.moduleName, payload.data);
    },

    [UPDATE_CAPACITY]: (state, payload: UpdateCapacityDTO): void => {
      const elem = state.data[payload.offerId]?.items
        ?.find(({ id }) => id === payload.webmasterId) as WebmastersCapacityData;

      Vue.set(elem, "softCapacity", payload.softCapacity);
      Vue.set(elem, "capacityUpdatedAt", moment().toDate());
    },

    [UPDATE_APPROVE]: (state, payload: UpdateApproveDTO): void => {
      const elem = state.data[payload.offerId]?.items
        ?.find(({ id }) => id === payload.webmasterId) as WebmastersCapacityData;

      Vue.set(elem, "requiredApprove", payload.updateOfferWebmasterRequiredApprove.requiredApprove);
      Vue.set(elem, "updatedAt", payload.updateOfferWebmasterRequiredApprove.updatedAt);
    },

    [UPDATE_SUBSECTIONS]: (state, payload): void => {
      const elem = state.data[payload.userId]?.items
        ?.find(({ id }) => id === payload.id) as WebmastersCapacityData;
      
      Vue.set(elem, "subsection", payload?.subsection);
    },

    [UPDATE_PAGINATION] (state, payload) {
      const { id, ...pagination } = payload;
      Vue.set(state.pagination, id, { ...state.pagination[id], ...pagination });
    },
  
    [UPDATE_SORTINGS] (state, { id, sort }) {
      state.sort[id] = sort.sortField;
      state.order[id] = sort.direction.toUpperCase();
    },
  
    [SET_EMPTY_DATA] (state, id) {
      state.data[id] = {};
    },
  
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
  
    [UPDATE_SUB_FILTERS] (state, payload) {
      const { offerId, ...filters } = payload;
      Vue.set(state.subFilters, offerId, { ...state.subFilters[offerId], ...filters });
    },
  
    [SET_PAYOUT] (state, payload) {
      const elem = state.data[payload.offerId]?.items
        ?.find(({ id }) => id === payload.webmasterId) as WebmastersCapacityItem;
      
      const newPayoutValue = {
        currency: elem.webmasterPayout.currency,
        value: payload.newPayoutValue
      };
      Vue.set(elem, "webmasterPayout", newPayoutValue);
      Vue.set(elem, "payoutUpdatedAt", payload.data.payoutUpdatedAt);
    },
  
    [SET_INDIVIDUAL_RATE] (state, payload) {
      const elem = state.data[payload.offerId]?.items
        ?.find(({ id }) => id === payload.webmasterId) as WebmastersCapacityItem;
      
      const newRate = {
        currency: elem.webmasterIndividualRate.currency,
        value: payload.webmasterRate
      };

      Vue.set(elem, "webmasterIndividualRate", newRate);
      Vue.set(elem, "rateUpdatedAt", payload.data.rateUpdatedAt);
    }
  }


  public actions: ActionTree<WebmastersCapacityState, RootState> = {
    async [GET_SUBSECTION_CAPACITY] ({ state, commit, getters, rootGetters }, { isRender, id }) {
      const limit = state.pagination[id]?.perPage || 20;
      const offset = limit * ((state.pagination[id]?.page || 1) - 1);
      const { isRequest, ...filters } = state.filters;
  
      const offerWebmastersCapacity = await GqlOffersService.getWebmastersCapacity(
        limit,
        offset,
        id,
        {
          ...filters,
          ...state.subFilters[id]
        },
        state.sort,
        state.order,
        isRequest ?? false,
        getters.isIndividualRate,
        rootGetters["admin/capacityAndBumps/capacity/advertisersCapacity/isShowRedemption"],
        rootGetters["admin/capacityAndBumps/logRequest/isShowCommission"]
      );
  
      if (!state.pagination[id] || _isEmpty(state.pagination[id])) {
        commit(UPDATE_PAGINATION, { id, page: 1, perPage: 20 });
      }
  
      if (!isRender && state.sort[id] && state.pagination[id]?.page === 1) {
        commit(SET_EMPTY_DATA, id);
      }
      
      commit(SET_SUBSECTION_CAPACITY, { ...offerWebmastersCapacity, id });
      return offerWebmastersCapacity;
    },

    async [UPDATE_APPROVE] ({ commit }, input) {
      const { data: { updateOfferWebmasterRequiredApprove } } = await GqlOffersService.updateApprove(input);
      commit(UPDATE_APPROVE, { updateOfferWebmasterRequiredApprove, offerId: input.offerId, webmasterId: input.webmasterId });
    },

    async [UPDATE_CAPACITY] ({ commit }, input) {
      const { data: { setCapacity: { softCapacity, offer, user } } } = await GqlOffersService.updateCapacity(input);
      commit(UPDATE_CAPACITY, { softCapacity, offerId: offer.id, webmasterId: user.id });
    },
  
    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
      commit(UPDATE_PAGINATION, { id: payload.id, page: 1 });
    },
  
    [UPDATE_SUB_FILTERS] ({ commit }, payload) {
      commit(UPDATE_SUB_FILTERS, payload);
      commit(UPDATE_PAGINATION, { id: payload.offerId, page: 1 });
    },
  
    [UPDATE_SUBSECTION_REQUEST] ({ commit, getters }, payload) {
      commit(SET_SUBSECTION_REQUEST, payload);
    
      const { data, moduleName } = payload;
      if (data.status !== "APPROVED") return;
      
      if (moduleName === "capacityRequest" || moduleName === "bookingCapacityRequest") {
        commit(UPDATE_CAPACITY, { softCapacity: +data.newCapacityValue, ...payload });
      } else if (moduleName !== "bookingCapacityRequest") {
        commit(SET_PAYOUT, { newPayoutValue: +data.newPayoutValue, ...payload });
    
        if (getters.isIndividualRate) {
          commit(SET_INDIVIDUAL_RATE, { webmasterRate: +data.webmasterRate, ...payload });
        }
      }
    }
  }

  private readonly initState: () => WebmastersCapacityState;

  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): WebmastersCapacityState => {
      return {
        data: {},
        pagination: {},
        filters: {},
        sort: {},
        order: {},
        subFilters: {}
      };
    };

    this.mutations[SET_EMPTY] = (state, id) => {
      state.data[id] = {};
      state.subFilters[id] = {};
      state.filters = {};
      state.pagination[id] = this.initialPagination;
      if (state.sort) {
        state.sort[id] = null;
        state.order[id] = null;
      }
    };

    this.state = this.initState();
    this.modules = modules;

    return this;
  }
}

export const webmastersCapacity = new WebmastersCapacity({
  capacityAnalyse,
  guaranteeRequest,
  capacityRequest,
  bookingRequest,
  payoutRequest,
  cancelCapacityRequest
});