import { defineStore } from "pinia";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import { OffersService } from "@core/services/admin/offers/offers";
import {
  OffersState as OriginalOffersState,
  PlanToStopOffer,
  WebmasterIndividualRates
} from "@core/store/types/admin/offers";

import { SortOrder } from "@core/store/types/advertiser/offers";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { pagination } from "@core/helpers/pagination";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { useVerticals } from "@/stores/common/auth/verticalsStore";

export interface OffersState extends OriginalOffersState {
  webmasterIndividualRates: WebmasterIndividualRates | null;
}

export const useOffers = defineStore("admin/offers", {
  state: (): OffersState => ({
    webmasterIndividualRates: null,
    data: null,
    filters: {},
    excludedFilters: {},
    sort: null,
    order: null
  }),

  getters: {
    showAdvertisers: () => {
      const store = usePermissions();
      return store.hasPermissions(["OFFERS.VIEW_INCLUDED_ADVERTISERS.OWN", "OFFERS.VIEW_INCLUDED_ADVERTISERS.ALL"]);
    },
    showIndividualRates: () => {
      const store = usePermissions();
      return store.hasPermissions(["OFFERS.MANAGE_PAYOUT.ALL", "OFFERS.MANAGE_PAYOUT.WEBMASTERS.OWN", "OFFERS.MANAGE_PAYOUT.ADVERTISERS.OWN"]);
    },
    isShowCommissions: () => {
      const store = usePermissions();
      return store.hasPermissions(["STATISTICS.SHOW_COMMISSION"]);
    },
    hasPayoutIndividualRates: () => (isBindedToCurrentAdmin: boolean) => {
      const store = usePermissions();
      const rules = ["OFFERS.MANAGE_PAYOUT.ALL", "OFFERS.MANAGE_PAYOUT.WEBMASTERS.OWN"];
      isBindedToCurrentAdmin && rules.push("OFFERS.MANAGE_PAYOUT.ADVERTISERS.OWN");

      return store.hasPermissions(rules);
    }
  },

  actions: {
    async GET_OFFERS (): Promise<void> {
      const verticalsStore = useVerticals();
      const { filters, excludedFilters, sort, order } = this;
      const { limit, offset } = pagination();
      const [withdrawType, subGoalType ] = filters.withdrawType?.split("-") || [null, null];

      this.data = await GqlOffersService.getOffers(
        { ...filters, withdrawType, subGoalType, vertical: verticalsStore.currentVertical },
        excludedFilters,
        limit,
        offset,
        sort,
        order,
        this.isShowCommissions,
        this.showAdvertisers,
        this.showIndividualRates
      );
    },

    async DELETE_OFFER (offerId: string) {
      const deletedOffer = await OffersService.deleteOffer(offerId);
      useStateUpdater(useOffers).LOCAL_DELETE({ items: [deletedOffer] });
    },

    async LAUNCH_OFFER (offerId: string) {
      const launchedOffer = await OffersService.launchOffer(offerId);
      useStateUpdater(useOffers).LOCAL_UPDATE({ items: [launchedOffer] });
    },

    async STOP_OFFER (offerId: string) {
      const stoppedOffer = await OffersService.stopOffer(offerId);
      useStateUpdater(useOffers).LOCAL_UPDATE({ items: [stoppedOffer] });
    },

    async PLAN_TO_STOP_OFFER (data: PlanToStopOffer) {
      const planToStopOffer = await OffersService.planToStopOffer(data);
      useStateUpdater(useOffers).LOCAL_UPDATE({ items: [planToStopOffer] });
    },

    UPDATE_SORTINGS (payload: { sort: OffersState["sort"], order: SortOrder }) {
      this.sort = payload.sort;
      this.order = payload.order?.toUpperCase() as SortOrder;
    }
  }
});
