import { AdministratorsEditService } from "@core/services/admin/users/administrators/AdministratorsEditService";
import { CREATE_ADMINISTRATOR, EDIT_ADMINISTRATOR } from "@core/store/action-constants";
import {
  SET_ADMINISTRATORS_EDIT_FORM,
  SET_EMPTY,
  SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE,
  UPDATE_ADMINISTRATORS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdministratorsEditState } from "@core/store/types/admin/users/administrators/Edit";
import { AdminForm } from "@core/store/logic/admin/users/adminiastrators/AdminForm";
import { User } from "@core/store/types/common/User";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): AdministratorsEditState => {
  return {
    isModalActive: false,
    administratorId: null,
    form: {
      countries: [],
      inHouse: null
    }
  };
};

const mutations: MutationTree<AdministratorsEditState> = {
  [UPDATE_ADMINISTRATORS_EDIT_FORM] (state, form: AdministratorsEditState["form"]) {
    state.form = { ...state.form, ...form };
  },

  [SET_ADMINISTRATORS_EDIT_FORM] (state, administrator: User) {
    state.administratorId = administrator.id;
    state.form = new AdminForm(administrator);
  },

  [SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE] (state, value: boolean) {
    state.isModalActive = value;
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<AdministratorsEditState, RootState> = {
  async [EDIT_ADMINISTRATOR] ({ state: { administratorId, form }, commit }) {
    const editedAdmin = await AdministratorsEditService.editAdmin(administratorId as string, form);
    commit("admin/users/administrators/LOCAL_UPDATE", { items: editedAdmin, target: "administrators" }, { root: true });
  },

  async [CREATE_ADMINISTRATOR] ({ state: { form }, commit }) {
    const createdAdmin = await AdministratorsEditService.createAdmin(form);
    commit("admin/users/administrators/LOCAL_ADD", { items: createdAdmin, target: "administrators" }, { root: true });
  }
};

export const edit: Module<AdministratorsEditState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
