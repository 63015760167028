import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import createGuaranteeRequest from "@core/services/admin/offers/graphql/guarantee/createGuaranteeRequest.graphql";
import deleteGuaranteeRequest from "@core/services/admin/offers/graphql/guarantee/deleteGuaranteeRequest.graphql";
import approveGuaranteeRequest from "@core/services/admin/offers/graphql/guarantee/approveGuaranteeRequest.graphql";
import rejectGuaranteeRequest from "@core/services/admin/offers/graphql/guarantee/rejectGuaranteeRequest.graphql";
import guaranteeStatistic from "@core/services/admin/offers/graphql/guarantee/guaranteeStatistic.graphql";
import {
  PayoutRequestInput,
  GuaranteeStatisticParams,
  GuaranteeStatisticResponse,
  RejectGuaranteeRequest,
  ApproveGuaranteeRequest,
  DeleteGuaranteeRequest,
  CreateGuaranteeRequest, GuaranteeRequestInput
} from "@core/store/types/admin/offers/capacity";

export class GuaranteeRequestService extends Service {
  public static createGuaranteeRequest (
    input: PayoutRequestInput
  ): AxiosPromise<CreateGuaranteeRequest> {
    try {
      return this.api.post("", {
        query: createGuaranteeRequest,
        variables: { input }
      });
    }
    catch (e) {
      throw e;
    }
  }

  public static getGuaranteeStatistic ({ offerId, webmasterId, dateStart, dateEnd, ...otherFIlters }: GuaranteeStatisticParams): Promise<GuaranteeStatisticResponse> {
    try {
      const date = { dateStart, dateEnd };

      return this.api.post("", {
        query: guaranteeStatistic,
        variables: { filtersStatistic: { offerId: [offerId], webmasterId, ...date, ...otherFIlters } }
      });
    }
    catch (e) {
      throw e;
    }
  }

  public static deleteGuaranteeRequest (id: string): AxiosPromise<DeleteGuaranteeRequest> {
    try {
      return this.api.post("", {
        query: deleteGuaranteeRequest,
        variables: { id }
      });
    }
    catch (e) {
      throw e;
    }
  }

  public static approveGuaranteeRequest (
    input: GuaranteeRequestInput,
    id: string
  ): AxiosPromise<ApproveGuaranteeRequest> {
    try {
      return this.api.post("", {
        query: approveGuaranteeRequest,
        variables: { input, id }
      });
    }
    catch (e) {
      throw e;
    }
  }

  public static rejectGuaranteeRequest (id: string, comment?: string | null): AxiosPromise<RejectGuaranteeRequest> {
    try {
      return this.api.post("", {
        query: rejectGuaranteeRequest,
        variables: { id, comment }
      });
    }
    catch (e) {
      throw e;
    }
  }
}
