import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import allowedOfferParameters from "@core/services/admin/capacityAndBumps/logRequest/graphql/allowedOfferParameters.graphql";

export class AllowedOfferParametersService extends Service {
  public static async getAllowedOfferParameters (webmasterId: string, offerId: string, externalWebmasterId?: string): Promise<AxiosPromise> {
    try {
      const { data: { allowedOfferParameters: data } } = await this.api.get("", {
        params: {
          query: allowedOfferParameters,
          variables: {
            offerId,
            webmasterId,
            externalWebmasterId
          }
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
