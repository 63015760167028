<template>
    <form
        class="content is-relative"
        @submit.prevent.stop="captchaExecute">
        <img
            :alt="$saas.name"
            class="form-logo"
            :class="{'pb-0 pl-4': !$saas.main}"
            :style="{'height': !$saas.main && '100px'}"
            :src="require(`@/assets/${ $saas.logo }`)">

        <h3 class="title is-4 has-text-centered">
            {{ $t(`common.auth.signIn.title`) }}
        </h3>
        <b-notification
            v-show="isLoginByPasswordError || isLoginByTelegramError"
            :closable="false"
            type="is-danger">
            <p>
                <i18n
                    v-if="isLoginByPasswordError"
                    path="common.auth.signIn.notification.loginPasswordError.content">
                    <template #login>
                        <span
                            v-t="'common.auth.signIn.notification.loginPasswordError.particles.login'"
                            class="has-text-weight-semibold"></span>
                    </template>
                    <template #password>
                        <span
                            v-t="'common.auth.signIn.notification.loginPasswordError.particles.password'"
                            class="has-text-weight-semibold"></span>
                    </template>
                </i18n>
                <i18n
                    v-if="isLoginByTelegramError"
                    path="common.auth.signIn.notification.loginTelegramError.content">
                    <template #login>
                        <span
                            v-t="'common.auth.signIn.notification.loginTelegramError.particles.login'"
                            class="has-text-weight-semibold"></span>
                    </template>
                </i18n>
            </p>
        </b-notification>

        <b-notification
            v-show="isTelegramError"
            :closable="false"
            type="is-danger">
            <i18n path="common.auth.signIn.notification.telegramError.content">
                <template #username>
                    <span
                        v-t="'common.auth.signIn.notification.telegramError.particles.username'"
                        class="has-text-weight-semibold"></span>
                </template>
            </i18n>
        </b-notification>

        <b-notification
            v-show="isUserBlocked"
            :closable="false"
            type="is-danger">
            <i18n
                path="common.auth.signIn.notification.banMessage"
                tag="p">
                <template #tg>
                    <a
                        v-if="$saas.contacts.telegram"
                        :href="$saas.contacts.telegram"
                        rel="noopener"
                        target="_blank">@{{ $saas.contacts.telegram.split('/')[3] }}</a>
                </template>
                <template #email>
                    <a
                        v-if="$saas.contacts.email"
                        :href="$saas.contacts.email"
                        target="_blank">{{ $saas.contacts.email.split(':')[1] }}</a>
                </template>
            </i18n>
        </b-notification>

        <div class="columns is-multiline mb-0">
            <b-field class="column is-marginless is-12">
                <LInput
                    v-model="login_"
                    required
                    :placeholder="$t(`common.auth.signIn.login`)"
                    icon-pack="uil"
                    icon="user">
                </LInput>
            </b-field>

            <b-field class="column is-marginless is-12">
                <LInput
                    v-model="password"
                    required
                    :placeholder="$t(`common.auth.signIn.password`)"
                    icon="unlock"
                    icon-pack="uil"
                    type="password">
                </LInput>
            </b-field>

            <b-button
                class="form-forgot has-text-left mb-3"
                tag="router-link"
                type="is-text"
                :to="{ name: 'auth:reset' }"
                @click.native.prevent="toReset">
                {{ $t(`common.auth.signIn.reset`) }}
            </b-button>
        </div>

        <Captcha
            ref="captcha"
            @onVerify="submit('password', null, $event)">
        </Captcha>

        <div class="columns is-multiline">
            <div
                class="column is-marginless is-12 flex is-justify-content-center">
                <b-button
                    ref="submitBtn"
                    :loading="isLoading"
                    class="is-success"
                    expanded
                    native-type="submit"
                    rounded>
                    {{ $t(`common.auth.signIn.signIn`) }}
                </b-button>
            </div>
            <div
                v-if="telegramLogin"
                class="column is-marginless is-12 py-0 has-text-centered">
                <template v-if="isValidDomain">
                    <VueTelegramLogin
                        :telegram-login="telegramLogin"
                        mode="callback"
                        size="medium"

                        @callback="submit('telegram', $event)">
                    </VueTelegramLogin>
                </template>
            </div>
            <div
                v-else
                class="column is-marginless is-12 py-0">
                <b-button
                    :loading="isLoading"
                    class="is-success"
                    expanded
                    rounded
                    @click="submit('telegram', null)">
                    [TEST] Telegram login
                </b-button>
            </div>
        </div>
  
        <div
            class="is-flex is-justify-content-center is-flex-wrap-wrap signUp">
            <span
                class="is-size-7 is-flex is-align-items-center"
                style="padding-left: 1em">
                {{ $t("common.auth.signIn.signUpReason") }}
            </span>
            <b-button
                type="is-text"

                class="form-subbutton"
                @click="isRoleModal = true">
                {{ $t(`common.auth.signIn.signUp`) }}
            </b-button>
        </div>
        <SignUpRoleModal :is-active.sync="isRoleModal"></SignUpRoleModal>
    </form>
</template>

<script>
  import SignUpRoleModal from "@/components/Common/Auth/SignUpRoleModal";
  import { USER_LOGIN_BY_PASSWORD, USER_LOGIN_BY_TELEGRAM } from "@core/store/action-constants";
  import { vueTelegramLogin as VueTelegramLogin } from "vue-telegram-login";
  import { mapActions, mapGetters, mapState } from "vuex";
  import Captcha from "@/components/Common/Auth/Captcha";

  export default {
    name: "SignIn",
    components: {
      VueTelegramLogin,
      Captcha,
      SignUpRoleModal
    },
    
    props: {
      login: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        loginRaw: null,
        password: null,
        isLoginByPasswordError: false,
        isLoginByTelegramError: false,
        isTelegramError: false,
        isLoading: false,
        isResetModal: false,
        isRoleModal: false
      };
    },
    
    computed: {
      ...mapState({
        fingerPrint: ({ auth }) => auth.fingerPrint
      }),

      ...mapGetters(["isUserBlocked"]),
      
      login_: {
        get () {
          return this.login ? this.login : this.loginRaw;
        },
        
        set (value) {
          this.loginRaw = value;
        }
      },

      telegramLogin () {
        return process.env.VUE_APP_TELEGRAM_BOT;
      },
      
      isValidDomain () {
        return window.location.origin.toLowerCase() === process.env.VUE_APP_CPA_URL.toLowerCase();
      }
    },
    methods: {
      ...mapActions(["USER_LOGOUT"]),

      toReset (event) {
        // Не удалять
        event.preventDefault();
        this.isResetModal = true;
      },
      
      async submit (type, user, captcha) {
        let action;
        
        if (type === "telegram") {
          this.isTelegramError = false;
          
          if (process.env.NODE_ENV === "development") {
            const fakeUserData = {
              "id": 123456,
              "first_name": "FN",
              "last_name": "LN",
              "username": "telegram",
              "photo_url": "http://test.test",
              "auth_date": Math.floor(+new Date / 1000)
            };
            
            const generateTelegramResponse = (await import("@/utils/generateTelegramResponse")).default;
            
            user = user ?? await generateTelegramResponse(fakeUserData);
          }
          
          if(user.username == null) {
            this.isTelegramError = true;
          } else {
            action = this.loginByTelegram({
              captcha: captcha,
              ...user
            });
          }
        } else {
          action = this.loginByPassword({
            login: this.login_,
            password: this.password,
            captcha: captcha,
            fingerPrint: this.fingerPrint
          });
        }
        
        this.USER_LOGOUT();
        
        this.isLoginByPasswordError = false;
        this.isLoginByTelegramError = false;
        this.isLoading = true;
        if (action) {
          action.then(() => {
            if (!this.isUserBlocked) {
              this.$router.push({ name: "dashboard" }).catch(_ => {});
            }
          }).catch(() => {
            if (type === "telegram") {
              this.isLoginByTelegramError = true;
            } else {
              this.isLoginByPasswordError = true;
            }
          }).finally(() => {
            this.isLoading = false;
          });
        }
      },
      
      loginByPassword (user, captcha) {
        return this.$store.dispatch(USER_LOGIN_BY_PASSWORD, user, captcha);
      },
      
      loginByTelegram (user, captcha) {
        return this.$store.dispatch(USER_LOGIN_BY_TELEGRAM, user, captcha);
      },
      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
