<template>
    <div>
        <b-table
            ref="lTable"
            :key="loading"
            :data="tableData"
            :loading="loading"
            :per-page="+_perPage"
            :current-page="+_page"
            :total="count"
            :backend-pagination="backendPagination"
            :backend-sorting="backendSorting"
            :class="[{ 'with-options': withOptions }, { 'is-inline-block': isStickyPagination }]"
            class="common-table"
            :pagination-position="paginationPosition"
            :paginated="!hidePagination"
            :hoverable="hoverable"
            :checkable="checkable"
            :is-row-checkable="isRowCheckable"
            :checked-rows="selected"
            :custom-detail-row="customDetailRow"
            :detail-key="detailKey"
            :detailed="detailed"
            :default-sort="defaultSort || localDefaultSort"
            :show-detail-icon="showDetailIcon"
            :mobile-cards="!hideMobile"
            @check="check"
            @check-all="checkAll"
            @page-change="pageChange($event)"
            @details-open="$emit('details-open', $event)"
            @details-close="$emit('details-close', $event)"
            @sort="sort">
            <template
                #header="{ column }">
                <slot
                    name="header"
                    :column="column"></slot>
            </template>

            <slot name="default">
            </slot>

            <template #footer>
                <slot name="footer">
                </slot>
            </template>

            <template #pagination>
                <LPagination
                    class="pagination mb-0"
                    :page-input="pageInput"
                    :count="count"
                    :items-length="data.length"
                    :query-pagination="queryPagination"
                    :page="_page"
                    :per-page="_perPage"
                    :limit="limit"
                    :debounce-page-input="+debouncePageInput"
                    @update="updatePagination">
                </LPagination>
            </template>

            <template #empty>
                <slot name="empty"></slot>
            </template>

            <template #loading>
                <LLoading
                    :is-full-page="false"
                    :active="loading"
                    :is-label-exist="true">
                </LLoading>
            </template>

            <template #detail="{ row, index }">
                <slot
                    name="detail"
                    :row="row"
                    :index="index">
                </slot>
            </template>
        </b-table>
        <SnackbarActions v-if="checkable">
            <slot name="actionsForSelected"></slot>
        </SnackbarActions>
        <slot name="infiniteLoading">
            <div
                v-if="isInfiniteLoading_ && virtualScroll">
                <InfiniteLoading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler">
                    <div slot="spinner"></div>
                </InfiniteLoading>
                <TableVirtualLoading>
                </TableVirtualLoading>
            </div>
        </slot>
    </div>
</template>
<script>
  import TableVirtualLoading from "@/components/Common/Table/TableVirtualLoading";
  import BaseTable from "@/components/Common/Base/BaseTable";
  import LLoading from "@/components/Common/LLoading";
  import LPagination from "@/components/Common/LPagination";
  import InfiniteLoading from "vue-infinite-loading";
  import _uniqBy from "lodash/uniqBy";
  import _intersectionBy from "lodash/intersectionBy";
  import _differenceBy from "lodash/differenceBy";
  import { StickyTableMixin } from "@/mixins/table/stickyTableMixin";
  import SnackbarActions from "@/components/Common/SnackbarActions.vue";
  import { mapActions, mapState } from "vuex";
  import { SET_EMPTY, UPDATE_SELECTED } from "@core/store/action-constants";

  export default {
    name: "LTable",
    extends: BaseTable,
    mixins: [StickyTableMixin],
    components: {
      LPagination,
      TableVirtualLoading,
      LLoading,
      InfiniteLoading,
      SnackbarActions
    },

    mounted () {
      if (!this.virtualScroll) {
        this.resizeTable();
      }
      this.initializeObservers();
    },

    data () {
      return {
        virtualCurrentPage: 1,
        averageHeightRow: 65,
        headerHeight: 30,
        tableHeight: 0,
        infiniteId: +new Date(),
        localDefaultSort: []
      };
    },

    computed: {
      ...mapState({
        selected: ({ snackbarActions }) => snackbarActions.selected
      }),

      _page () {
        return this.queryPagination ? this.$route.query.page : this.page;
      },

      _perPage () {
        return this.queryPagination ? this.$route.query.perPage : this.perPage;
      },

      tableData () {
        return this.virtualScroll ? this.visibleData : this.data;
      },

      paginationPosition () {
        if (this.tableHeight > 1300) {
          if (!this.virtualScroll || this.visibleData?.length === this.data.length) {
            return "both";
          }
        }
        return "top";
      },

      isInfiniteLoading_ () {
        return this.visibleData.length < this.data.length;
      },

      visibleData () {
        return this.data.slice(0, this.virtualCurrentPage * this.virtualPerPage);
      }
    },

    methods: {
      ...mapActions("snackbarActions", {
        UPDATE_SELECTED,
        SET_EMPTY_SELECTED: SET_EMPTY
      }),

      updatePagination ({ key, value }) {
        this.$emit(`update:${ key }`, value);
        this.$emit("update");
      },

      check (checkedList, row) {
        if (row != null) {
          this.UPDATE_SELECTED(checkedList);
        }
      },

      checkAll () {
        const checkableData = this.data.filter(item => item.isCheckable !== false);

        if (_intersectionBy(checkableData, this.selected, "id").length === checkableData.length) {
          this.UPDATE_SELECTED(_differenceBy(this.selected, checkableData, "id"));
        } else {
          this.UPDATE_SELECTED(_uniqBy([...this.selected, ...checkableData], "id"));
        }
      },

      resizeTable () {
        this.$nextTick(() => {
          this.tableHeight = this.$refs.lTable?.$el.clientHeight;
        });
      },

      infiniteHandler ($state) {
        this.virtualCurrentPage++;
        this.resizeTable();
        this.$emit("updateVirtualPagination", {
          virtualPage: this.virtualCurrentPage,
          virtualPerPage: this.virtualPerPage
        });

        $state.loaded();
      },

      updateSelected () {
        const intersectionData = _intersectionBy(this.data, this.selected, "id");
        const newSelected = _uniqBy([ ...intersectionData, ...this.selected ], "id");
        this.UPDATE_SELECTED(newSelected);
      }
    },
    watch: {
      loading (value) {
        if (!value) {
          this.virtualCurrentPage = 1;
          this.$emit("updateVirtualPagination", {
            virtualPage: this.virtualCurrentPage,
            virtualPerPage: this.virtualPerPage
          });
          this.resizeTable();
          this.infiniteId += 1;

          if (this.selected?.length) {
            this.updateSelected();
          }
        }
      }
    }
  };
</script>

<style lang="scss">
    body {
        &.is-mini {
            .table-wrapper {
                th.is-sticky,
                td.is-sticky,
                tr.is-subheading th:first-child,
                .table-footer th:first-child {
                    left: calc(#{ $aside-width-until-widescreen } + 1rem) !important;

                    //noinspection SassScssUnresolvedMixin
                    @include touch {
                        left: 1rem !important;
                    }
                }
            }
        }

        &:not(.is-mini) {
            .table-wrapper {
                th.is-sticky,
                td.is-sticky {
                    //noinspection SassScssUnresolvedMixin
                    @include until-widescreen {
                        left: calc(#{ $aside-width-widescreen } + 1rem) !important;
                    }

                    //noinspection SassScssUnresolvedMixin
                    @include touch {
                        left: 1rem !important;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .pagination {
        padding-top: $size-4;
        padding-bottom: $size-4;

        &:last-of-type {
            padding-top: 0;
        }
    }

    ::v-deep {
        .pagination-input {
            order: 4 !important;
        }

        .common-table {
            tfoot {
                position: sticky;
                bottom: 0;
                z-index: 2;
                background: #F5F8FA;
                td {
                    border: none;
                }
                .total-number {
                    font-size: 14px;
                }
            }

            .table-wrapper {
                overflow: inherit !important;

                th.is-sticky,
                td.is-sticky {
                    @include transition(left);

                    //noinspection SassScssUnresolvedMixin
                    @include widescreen {
                        left: calc(#{ $aside-width-widescreen } + 1rem);
                    }
                }

                th.is-sticky {
                    background-color: $base-color-light;
                    box-shadow: -24px 0px 0px $base-color-light;
                }

                td.is-sticky {
                    background-color: $white;
                    box-shadow: -24px 0px 0px $base-color-light;
                }
            }
        }
    }
</style>
