import VueInstance from "../../main";
import momentjs from "moment";
import Vue from "vue";
import i18n from "./plugins/i18n";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import { CurrencySymbol } from "@core/helpers/currencySymbolMap";
import filterQueryParams from "@core/helpers/filterQueryParams";
import { UnknownObject } from "@core/store/types/common";
import { fixedFormatPercent } from "@core/flowMethods";

export function momentInst (value?: Date | string, locale = i18n.locale): momentjs.Moment {
  const { offset } = VueInstance.$store.getters.timezone;
  
  return momentjs
    .utc(value)
    .utcOffset(offset)
    .locale(locale);
}

export function moment (
  value?: Date | string,
  format = "DD MMMM YYYY HH:mm",
  withOffset = false,
  dateModifier = (momentInstance: momentjs.Moment): momentjs.Moment => momentInstance,
  locale = i18n.locale
): string {
  const { offset } = VueInstance.$store.getters.timezone;
  const formatToken = withOffset ? format + offset : format;
  const momentInstance = momentInst(value, locale);
  
  return dateModifier(momentInstance).format(formatToken);
}

export function momentFrom (value?: Date | string, locale = i18n.locale): string {
  return momentjs(value).locale(locale).fromNow();
}

export function formatEmptyString (value: any, emptyString = "-"): string {
  value = value || value === 0 ? String(value).trim() : value;
  return value && value !== "Invalid date" && value !== "NaN" && !(typeof value === "number" && isNaN(value)) ?
    value : emptyString;
}

export function toFixed (value: any, digits = 0): string | undefined {
  if (value != null) {
    return parseFloat(value).toFixed(digits);
  }
}

export function numberDigit (value: number): string | undefined {
  return Number(value).toLocaleString("ru-RU");
}

export function defaultFormatter (value: any, digits = 2) {
  if (value != null) {
    const _value = parseFloat(Number(value)?.toFixed(digits)) as any;
    const res = numberDigit(_value === parseInt(_value) ? _value : _value.toFixed(digits));
    return isNaN(_value) ? value : res;
  }
}

export function encodeFilters (filters: UnknownObject) {
  return encodeURIComponent(JSON.stringify(filterQueryParams(filters)));
}

export function formatPercent (value: any): number {
  return parseFloat(value || 0) * 100;
}

export function hasInfinity (value: any): number {
  return value === Infinity || value === -Infinity || isNaN(value) ? 0 : value;
}

export function getPercentDifference (
  oldValue: Nullable<number>,
  newValue: Nullable<number>,
  digitsAfterComma = 2
) {
  if (oldValue === newValue) {
    return 0;
  } else if (oldValue === 0 || newValue === 0) {
    return 100;
  } else if (oldValue && newValue) {
    return fixedFormatPercent(Math.abs(oldValue - newValue) / oldValue);
  }
}

export function formatCurrency (value: any, currency = "USD"): string | undefined {
  // @ts-ignore
  const symbolFromCurrency = CurrencySymbol[currency?.toUpperCase()];
  const formatValue = defaultFormatter(value);

  if (value == null) {
    return formatEmptyString(value);
  }

  if (symbolFromCurrency) {
    return `${ formatValue } ${ symbolFromCurrency }`;
  }

  return formatValue;
}

export function formatNumberToThousands (number: number): string {
  if (number == null) {
    return formatEmptyString(number);
  }
  return String(Math.trunc(number / 1000));
}

export function insertLineBreaks (str: string, maxLength: number) {
  const regex = new RegExp(".{1," + maxLength + "}(\\s|$)", "g");
  return str.replace(regex, "$&\n").replace(/\n$/, "");
}

export function capitalizeFirstLetter (string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function currencySign (currency: CurrencyEnum) {
  return CurrencySymbol[currency];
}

export function globalSource (globalSource: string) {
  const globalSources: { [key: string]: string } = {
    smsNewsletter: "SMS_NEWSLETTER",
    inApp: "IN_APP",
    emailNewsletter: "EMAIL_NEWSLETTER",
    context: "CONTEXT",
    teaser: "TEASER",
    clickunderPopunder: "CLICKUNDER_POPUNDER",
    messengers: "MESSENGERS",
    SEO: "SEO",
    FB: "FB",
    nativeAd: "NATIVE_AD",
    bannerNets: "BANNER_NETS",
    pushNotifications: "PUSH_NOTIFICATIONS",
    googleSeo: "GOOGLE_SEO",
    doorway: "DOORWAY",
    googleKMS: "GOOGLE_KMS"
  };
  return globalSources[globalSource];
}

Vue.filter("moment", moment);
Vue.filter("momentFrom", momentFrom);
Vue.filter("formatEmptyString", formatEmptyString);
Vue.filter("formatPercent", formatPercent);
Vue.filter("toFixed", toFixed);
Vue.filter("formatCurrency", toFixed);


