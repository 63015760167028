<template>
    <v-tippy
        ref="tippy"
        :key="key"
        :content="label"
        :arrow="arrow"
        :placement="getPosition"
        :offset="offset"
        :delay="delay"
        :disabled="disabled"
        :ignore-attributes="ignoreAttributes"
        :trigger="trigger"
        :theme="getTheme"
        :interactive="interactive"
        :flip-on-update="flipOnUpdate"
        :boundary="boundary"
        :size="getSize"
        :max-width="maxWidth"
        :flip-behavior="flipBehavior"
        :on-hide="() => !always"
        :on-hidden="onHidden"
        :on-shown="onShown"
        :on-show="onShow"
        class="tippy">
        <template #trigger>
            <slot></slot>
        </template>
        <slot name="content"></slot>
    </v-tippy>
</template>

<script>
  import BaseTooltip from "@/components/Common/Base/BaseTooltip.vue";

  export default {
    name: "LTooltip",
    extends: BaseTooltip,

    data () {
      return {
        key: false
      };
    },

    methods: {
      onShow () {
        this.$emit("onShow");
        return this.active;
      },
      
      onShown () {
        this.$emit("onShown");
        return this.active;
      },

      onHidden () {
        this.$emit("onHidden");
      }
    },

    watch: {
      always (value) {
        if (!value) {
          this.key = !this.key;
        }
      }
    }
  };
</script>

<style lang="scss">
    $colors: (
            "white": ($white),
            "light": ($light),
            "primary": ($primary),
            "info": ($info),
            "success": ($success),
            "warning": ($warning),
            "danger": ($danger),
    );

    $x-placements: (
        top: (left, right),
        bottom: (left, right),
        right: (top, bottom),
        left: (top, bottom)
    );

    .vs__selected .before .tippy {
        display: inline-block;
    }

    .tippy {
        display: inline-block;

        &-tooltip {
            display: flex;
            align-items: center;
            min-height: 33px;
        }
    }

    .column > .tippy,
    .field .tippy {
        display: block;
    }

    .tippy-content {
        white-space: pre-line;
        text-align: left;
        overflow: hidden;
        word-wrap: break-word;

        .tooltip-content-list {
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    @each $name, $glyph in $colors {
        .tippy-tooltip.#{$name}-theme {
            /* Your styling here. Example: */
            background-color: $glyph;
            border: 2px solid $glyph;
            filter: drop-shadow(0 2px 3px rgba(0, 1, 0, 0.2));

            @if($name == "white") {
                color: black;
             }
        }

        @each $x-placement, $sides in $x-placements {
            .tippy-popper[x-placement^=#{$x-placement}] .tippy-tooltip.#{$name}-theme .tippy-arrow {
                border-#{$x-placement}-color: $glyph;

                @if($name == "white") {
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        border-#{$x-placement}: 3px solid #f5f5f5;
                        transform-origin: 0 50%;

                        @each $side in $sides {
                            border-#{$side}: 3px solid transparent;
                        }
                    }
                }
            }

            .tippy-popper {
                &[x-placement^=right] .tippy-arrow:after {
                    margin: -5px -2px;
                }

                &[x-placement^=left] .tippy-arrow:after {
                    margin: -5px -5px;
                }

                &[x-placement^=top] .tippy-arrow:after {
                    margin: -5px -5px;
                }

                &[x-placement^=bottom] .tippy-arrow:after {
                    margin: -2px -5px;
                }
            }
        }
    }
</style>
