import { Integrations, IntegrationServices } from "@core/logic/admin/integrations/integrations";
import { IntegrationService } from "@core/services/admin/integrations/IntegrationsService";
import {
  SET_EMPTY,
  CREATE_INTEGRATION,
  DELETE_INTEGRATION,
  GET_INTEGRATIONS,
  LAUNCH_INTEGRATION,
  STOP_INTEGRATION,
  VALIDATE_CRM_LEAD_GROUP
} from "@core/store/action-constants";
import { CRMList } from "@core/store/modules/admin/lists/CRMList";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import {
  SET_INTEGRATIONS,
  UPDATE_INTEGRATIONS_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { IntegrationState } from "@core/store/types/admin/integrations";
import { pagination } from "@core/helpers/pagination";

export type PartialIntegrationState = IntegrationState;

const initialState = (): PartialIntegrationState => {
  return {
    integrations: null,
    filters: {
      advertiser: null,
      offer: null,
      crm: null,
      country: null
    }
  };
};

const state: () => PartialIntegrationState = initialState;

const mutations: MutationTree<PartialIntegrationState> = {
  [SET_INTEGRATIONS] (state, items: Integrations) {
    state.integrations = items;
  },

  [UPDATE_INTEGRATIONS_FILTERS] (state, filter: PartialIntegrationState["filters"]) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [SET_EMPTY] (state, target?: keyof PartialIntegrationState) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<PartialIntegrationState, RootState> = {
  async [GET_INTEGRATIONS] ({ state: { filters }, commit }) {
    const { limit, offset } = pagination();

    try {
      const integrations = await IntegrationService.getIntegrations(
        limit,
        offset,
        filters
      );

      commit(SET_INTEGRATIONS, integrations);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [VALIDATE_CRM_LEAD_GROUP] ({ commit }, integrationId) {
    const validateIntegration = await IntegrationService.validateIntegration(integrationId);
    commit("LOCAL_UPDATE", { items: validateIntegration, target: "integrations" });
  },

  async [CREATE_INTEGRATION] ({ commit }, payload) {
    try {
      const createIntegration = await IntegrationServices.createIntegration(payload);
      commit("LOCAL_ADD", { items: createIntegration, target: "integrations" });
    } catch (e) {
      throw e;
    }
  },

  async [DELETE_INTEGRATION] ({ commit }, integrationId) {
    try {
      const removeIntegration = await IntegrationService.removeIntegration(integrationId);
      commit("LOCAL_DELETE", { items: removeIntegration, target: "integrations" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [LAUNCH_INTEGRATION] ({ commit }, integrationId: string) {
    try {
      const launchIntegration = await IntegrationService.launchIntegration(integrationId);
      commit("LOCAL_UPDATE", { items: launchIntegration, target: "integrations" });
    } catch (e) {
      throw e;
    }
  },
  
  async [STOP_INTEGRATION] ({ commit }, integrationId: string) {
    try {
      const stopIntegration = await IntegrationService.stopIntegration(integrationId);
      commit("LOCAL_UPDATE", { items: stopIntegration, target: "integrations" });
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_INTEGRATIONS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_INTEGRATIONS_FILTERS, filter);
  },

  SET_EMPTY ({ commit }, target?: PartialIntegrationState) {
    commit("SET_EMPTY", target);
    commit("offersList/SET_EMPTY");
    commit("CRMList/SET_EMPTY");
  }
};

export const integrations: Module<PartialIntegrationState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    offersList,
    CRMList
  }
};
