import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { globalSource } from "@core/filters";
import Vue from "vue";
import {
  SET_EMPTY_SUB_ANALYTIC,
  SET_SUB_ANALYTIC,
  UPDATE_SUB_ANALYTIC,
  SET_WEBMASTERS
} from "@core/store/mutation-constants";
import {
  GET_SUB_ANALYTIC_BY_INDEX,
  GET_SUB_ANALYTIC_ANALYSE,
  GET_SUB_ANALYTIC,
  GET_WEBMASTERS, SET_EMPTY
} from "@core/store/action-constants";
import {
  NullablePaginationAdminSubAnalyticState,
  AdminSubAnalyticState,
  SubAnalyticParams,
  AnalyticItem,
  SubAnalytic
} from "@core/store/types/admin/analytic/analytic";
import { WebmastersListResponse } from "@core/store/types/admin/offer/lists/OfferEditWebmastersList";
import _camelCase from "lodash/camelCase";
import { datepickerFormat } from "@core/helpers/datepicker";
import { fixedFormatPercent } from "@core/flowMethods";

const filterIds = (
  group: AnalyticItem["group"] | AnalyticItem["group"][]
): AnalyticItem["group"]["id"] | AnalyticItem["group"]["id"][] => {
  return Array.isArray(group)
    ? group.map(el => el.id)
    : group?.id ?? group;
};

const initialState = (): NullablePaginationAdminSubAnalyticState => {
  return {
    webmasters: null,
    subAnalytic: {}
  };
};

const state: () => NullablePaginationAdminSubAnalyticState = initialState;

const mutations: MutationTree<NullablePaginationAdminSubAnalyticState> = {
  [SET_EMPTY_SUB_ANALYTIC] (state, payload: {
    uniqueKey: number;
  }): void {
    const { uniqueKey } = payload;
    
    if (state.subAnalytic?.[uniqueKey]) {
      Vue.set(state.subAnalytic, uniqueKey, undefined);
    }
  },
  
  [SET_SUB_ANALYTIC] (state, payload: {
    uniqueKey: number;
    data: SubAnalytic;
  }): void {
    if (state.subAnalytic == null) {
      state.subAnalytic = {};
    }
    
    let { uniqueKey, data } = payload;
    
    if (state.subAnalytic[uniqueKey]) {
      data = {
        count: data.count,
        items: state.subAnalytic[uniqueKey].items.concat(data.items)
      };
    }
    
    Vue.set(state.subAnalytic, uniqueKey, data);
  },
  
  [UPDATE_SUB_ANALYTIC] (state, { uniqueKey, data }): void {
    // @ts-ignore
    Vue.set(state.subAnalytic, uniqueKey, data);
  },
  
  [SET_WEBMASTERS]: (state, webmasters: WebmastersListResponse["webmasters"]): void => {
    state.webmasters = webmasters;
  },
  
  [SET_EMPTY] (state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { webmasters, ...emptyFields } = initialState();
    Object.assign(state, emptyFields);
  }
};

const actions: ActionTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  async [GET_SUB_ANALYTIC] ({ dispatch, commit, state, rootState }, { rowData }: SubAnalyticParams): Promise<AdminSubAnalyticState["subAnalytic"]> {
    const { uniqueKey, subsection, groups: group, groupFilters, groupFields } = rowData;
    const subAnalytic = state.subAnalytic?.[uniqueKey];
    
    const { admin: { analytic: { filters: analyticFilters, options: { analysisMode } } }, verticals: { currentVertical: vertical } } = rootState;
    const {
      groups: analyticGroups,
      threshold,
      datepicker,
      registeredDatepicker,
      ...otherAnalyticFilters
    } = analyticFilters;
  
    const groupFiltersData = groupFilters
      ? groupFilters
      : analyticGroups[0].startsWith("UTM") ? { [_camelCase(analyticGroups[0])]: filterIds(group) } : { [`${ analyticGroups[0].toLowerCase() }Id`]: filterIds(group) };
    
    const groupFieldsData = groupFields
      ? groupFields
      : { [analyticGroups[0].toLowerCase()]: group };
    
    const { global_sourceId, actual_webmaster_idId, ...fields } = groupFiltersData;
    // TODO кринж. переписать логику добавления "Id" к названию полей
    
    const groupFiltersAnalytic = {
      ...fields,
      // @ts-ignore
      globalSource: globalSource(global_sourceId),
      actualWebmasterIntId: actual_webmaster_idId
    };
    let data;
    const limit = 10;
    const offset = subAnalytic?.items.length ?? 0;
    const filters = {
      ...datepickerFormat(datepicker, "date", true),
      ...registeredDatepicker,
      ...otherAnalyticFilters,
      vertical
    };
    
    if (analysisMode) {
      const { offerId, webmasterId } = groupFiltersData;
      const contextId = offerId ?? webmasterId;
      
      const params = { limit, offset, group: subsection, webmasterId, contextId, threshold, filters };
      
      data = await dispatch(GET_SUB_ANALYTIC_ANALYSE, params);
    } else {
      const { data: { analytic } } = await AnalyticService.getAnalytic(
        limit,
        offset,
        {
          ...filters,
          ...groupFiltersAnalytic
        },
        subsection,
        analyticGroups[1]
      );
      data = analytic;
    }
  
    data.items = data.items.map((element: AnalyticItem, i: number) => {
      const group = element.group;
      return {
        uniqueKey: new Date().getTime() + i,
        groupFilters: {
          ...groupFiltersData,
          [`${ subsection.toLowerCase() }Id`]: filterIds(group)
        },
        groupFields: {
          ...groupFieldsData,
          [subsection.toLowerCase()]: group
        },
        ...element
      };
    });
    
    commit(SET_SUB_ANALYTIC, {
      uniqueKey,
      data
    });
    
    return data;
  },
  
  async [GET_SUB_ANALYTIC_ANALYSE] ({ state }, { limit, offset, group, webmasterId, contextId, threshold, filters }) {
    const { data: { analyticAnalyse } } =
      await AnalyticService.getAnalyticAnalyse(limit, offset, group, contextId, threshold, filters);
    
    const { webmasters } = state;
    
    const data = analyticAnalyse.items?.map(({ group, total, items }) => {
      const userId = webmasterId ?? group.id;
      const hasOurWebmaster = webmasters?.items?.some(item => item.id === userId);
      
      const innerItems = items.map(item => {
        const {
          statistics: { countLeadsIntegrated: statisticLeadsIntegrated, ...otherStatisticFields },
          difference: { countLeadsIntegrated: differenceLeadsIntegrated },
          ...fields
        } = item;
        
        const yesterday = differenceLeadsIntegrated * -1 + statisticLeadsIntegrated;
        const difference = differenceLeadsIntegrated !== 0 && differenceLeadsIntegrated === statisticLeadsIntegrated ?
          1 : statisticLeadsIntegrated / yesterday - 1;
        const differencePercent = fixedFormatPercent(difference);
  
        return {
          differencePercent,
          hasOurWebmaster,
          difference,
          ...fields,
          ...otherStatisticFields,
          countLeadsIntegrated: statisticLeadsIntegrated
        };
      });
      
      return {
        group,
        total,
        items: innerItems
      };
    });
    
    return {
      items: data,
      count: analyticAnalyse.count
    };
  },
  
  async [GET_WEBMASTERS] ({ commit, rootState }) {
    const { admin: { profile: { user: { id: manager } } } } = rootState;
    
    const { data: { webmasters } } = await AnalyticService.getWebmastersIds({ manager });
    commit(SET_WEBMASTERS, webmasters);
  }
};

const getters: GetterTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  [GET_SUB_ANALYTIC_BY_INDEX]: state => (uniqueKey: string | number) => (state.subAnalytic || {})[uniqueKey]
};

export const subAnalytic: Module<NullablePaginationAdminSubAnalyticState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
