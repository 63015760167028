import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { SubCapacityAnalyseFiltersResponse } from "@core/store/types/admin/offers/capacity";

export default function subWebmastersCapacityAnalyse (
  offerId: string,
  webmasterId: string,
  filters: SubCapacityAnalyseFiltersResponse,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const params = {
    include: isShowRedemption ? ["conversionPayout"] : []
  };
  
  const gqlObject = {
    operation: "capacityGraphBySubWebmaster",
  
    fields: [
      {
        offer: [
          "id",
          "intId",
          {
            detail: [
              "name"
            ]
          },
          {
            finance: [
              "currency"
            ]
          }
        ]
      },
      {
        webmaster: [
          "id",
          "intId",
          "login"
        ]
      },
      "conversionApprove",
      "conversionSpam",
      "webmasterRate",
      "countLeadsIntegrated",
      "countLeadsSpamByAdvertiser",
      "countLeadsAccepted",
      "softCapacity",
      "date",
      "hasCapacityChanged",
      "hasWebmasterRateChanged"
    ],
    
    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      },
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      },
      filters: {
        type: "CapacityGraphBySubWebmasterFilters",
        value: filters
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}