import Service from "@core/services/common/Service";
import {
  CapacityAnalyseExternalWebmastersListFilters,
  CapacityAnalyseExternalWebmastersListResponse as CapacityAnalyseResponse
} from "@core/store/types/common/lists/CapacityAnalyseExternalWebmastersList";
import capacityAnalyseExternalWebmastersListQuery from "@core/services/admin/capacityAndBumps/common/graphql/capacityAnalyseExternalWebmastersList.graphql";

export class CapacityAnalyseExternalWebmastersListService extends Service {
  static async getExternalWebmastersList (
    offerId: Nullable<string>,
    webmasterId: Nullable<string>,
    filters: Nullable<NullableRecord<CapacityAnalyseExternalWebmastersListFilters>>,
    excludedFilters: Nullable<NullableRecord<CapacityAnalyseExternalWebmastersListFilters>>,
    limit: number,
    offset: number
  ): Promise<CapacityAnalyseResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: capacityAnalyseExternalWebmastersListQuery,
          variables: {
            offerId,
            webmasterId,
            excludedFilters,
            filters,
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}