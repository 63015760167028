import { useOffers } from "@/stores/admin/offers/offersStore";

export const detailingGamblingFieldsList = () => {
    const { isShowCommissions } = useOffers();

    return [
        {
            "label": "admin.statistics.statistics.table.labels.createdAt",
            "value": "createdAt"
        },
        {
            "label": "admin.statistics.statistics.table.labels.webmaster",
            "value": "webmaster.intId"
        },
        {
            "label": "admin.statistics.statistics.table.labels.leadId",
            "value": "id"
        },
        {
            "label": "admin.statistics.statistics.table.labels.country",
            "value": "country.name"
        },
        {
            "label": "admin.statistics.statistics.table.labels.domain",
            "value": "fields.domain"
        },
        {
            "label": "admin.statistics.statistics.table.labels.flow",
            "value": "flow.name"
        },
        {
            "label": "admin.statistics.statistics.table.labels.clickId",
            "value": "fields.clickId"
        },
        {
            "label": "admin.statistics.statistics.table.labels.remoteId",
            "value": "remoteId"
        },
        {
            "label": "admin.statistics.statistics.table.labels.ip",
            "value": "fields.ip"
        },
        {
            "label": "admin.statistics.statistics.table.labels.offerName",
            "value": "offer.detail.name"
        },
        {
            "label": "admin.statistics.statistics.table.labels.status",
            "value": "status"
        },
        isShowCommissions && {
            "label": "admin.statistics.statistics.table.labels.payouts",
            "value": "reward.webmasterReward.value"
        },
        {
            "label": "admin.statistics.statistics.table.labels.advertiserWriteOff",
            "value": "reward.advertiserWriteOff.value"
        },
        isShowCommissions && {
            "label": "admin.statistics.statistics.table.labels.currency",
            "value": "reward.webmasterReward.currency"
        },
        {
            "label": "admin.statistics.statistics.table.labels.utmcampaign",
            "value": "fields.utm_campaign"
        },
        {
            "label": "admin.statistics.statistics.table.labels.utmcontent",
            "value": "fields.utm_content"
        },
        {
            "label": "admin.statistics.statistics.table.labels.utmmedium",
            "value": "fields.utm_medium"
        },
        {
            "label": "admin.statistics.statistics.table.labels.utmsource",
            "value": "fields.utm_source"
        },
        {
            "label": "admin.statistics.statistics.table.labels.utmterm",
            "value": "fields.utm_term"
        }
    ].filter(Boolean);
};