<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isModalLoading"
        @submit="getRedirectData">
        <template #header>
            <h5 class="title is-5">
                {{ $t("advertiser.navBar.refill.header") }}
            </h5>
        </template>
        <div class="columns">
            <div class="column">
                <b-field :label="$t('advertiser.navBar.refill.body.method')">
                    <Select
                        v-model="refillForm.paymentSystemId"
                        :placeholder="$t('advertiser.navBar.refill.body.method')"
                        :get-data-vuex="getPaymentSystems"
                        :min-required-length="1"
                        field="name"
                        @option:selecting="updatePaymentCurrency">
                    </Select>
                </b-field>
            </div>
        </div>
        <div class="columns">
            <div class="column is-8">
                <b-field :label="$t('advertiser.navBar.refill.body.amount')">
                    <LInput
                        v-model.number="refillForm.amount"
                        :min="minRefillAmount"
                        placeholder="500"
                        step="0.01"
                        type="number">
                    </LInput>
                </b-field>
            </div>
            <div class="column">
                <b-field :label="$t('advertiser.navBar.refill.body.currency')">
                    <LInput
                        :value="currency"
                        disabled>
                    </LInput>
                </b-field>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            :disabled="isDisabled"
                            class="is-fullwidth is-info is-light"
                            native-type="submit">
                            {{ $t("advertiser.navBar.refill.footer.confirm") }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth is-light-button"
                            native-type="reset"
                            @click="closeModal">
                            {{ $t("advertiser.navBar.refill.footer.cancel") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import { Modal } from "@core/mixins";
  import { RefillService } from "@core/services/advertiser/payments/service";
  import { GET_PAYMENT_SYSTEMS } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    UPDATE_INCOMES_FILTERS,
    UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION,
    UPDATE_PAYMENT_SYSTEMS_FILTERS
  } from "@core/store/mutation-constants";
  import { currencySign } from "@core/filters";
  
  export default {
    name: "RefillModal",
    mixins: [Modal],
    components: {
      ModalBox,
      Select
    },

    data () {
      return {
        isModalLoading: false,
        refillForm: {
          amount: null,
          paymentSystemId: null
        },
        currency: null
      };
    },
    computed: {
      ...mapState("advertiser/finances/refill/refillModal/paymentSystemsList", {
        paymentSystems: ({ data }) => data
      }),

      minRefillAmount () {
        const MIN_AMOUNT_USD = 20;
        const MIN_AMOUNT_RUB = 10000;
        return this.currency === "RUB" ? MIN_AMOUNT_RUB : MIN_AMOUNT_USD;
      },

      isDisabled () {
        return !Object.values(this.refillForm).every(el => !!el);
      }
    },
    methods: {
      ...mapActions("advertiser/finances/refill/refillModal/paymentSystemsList", {
        GET_PAYMENT_SYSTEMS
      }),

      ...mapMutations("advertiser/finances/refill/refillModal/paymentSystemsList", {
        UPDATE_INCOMES_FILTERS,
        UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION,
        UPDATE_PAYMENT_SYSTEMS_FILTERS
      }),

      updatePaymentCurrency (option) {
        this.currency = option.currency;
      },

      isValid () {
        let error = null;
        if (this.refillForm.amount < this.minRefillAmount) {
          error = this.$t("advertiser.navBar.refill.messages.error", { amount: this.minRefillAmount, currency: currencySign(this.currency) });
        }

        if (error) {
          this.$buefy.toast.open({ message: error, type: "is-danger" });
        }

        return error == null;
      },

      createSyncRequest (formParams) {
        //  Я буду гореть в аду за этот восхитительный код. Сможете придумать лучше - флаг вам в лапки!
        //  Форма создается для того что бы запрос был *синхронным*.
        // Можно переписать используя this.$createElement
        const form = document.createElement("form");
        form.setAttribute("target", "_blank");
        this.isModalActive = false;

        form.method = formParams.method;
        form.action = formParams.link;

        for (const [key, value] of Object.entries(formParams.attribute)) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;

          form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        form.remove();
      },

      async getPaymentSystems (label = "") {
        this.UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION();
        this.UPDATE_PAYMENT_SYSTEMS_FILTERS({ name: label.length > 0 ? label : null, withAttachedToUser: null });
        await this.GET_PAYMENT_SYSTEMS();
        return this.paymentSystems;
      },

      getRedirectData () {
        if (this.isValid()) {
          this.isModalLoading = true;
          const payload = { ...this.refillForm, currency: this.currency };
          RefillService.getRefillInfo(payload).then(response => {
            this.createSyncRequest(response.data.formSetting);
          });
        }
      }
    },
    watch: {
      isModalActive (value) {
        if (value) {
          this.isModalLoading = false;
          this.refillForm = {
            amount: null,
            paymentSystemId: null
          };
        }
      },

      "refillForm.paymentSystemId" (value) {
        if (!value) {
          this.currency = null;
          this.refillForm.amount = null;
        }
      },

      minRefillAmount (value, oldValue) {
        if (value !== oldValue) {
          this.refillForm.amount = this.minRefillAmount;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .modal {
        &-header {
            display: block;
            margin-bottom: 10px;
            font-size: 20px;
            color: #131313;
        }

        &-sum {
            margin-right: 10px;
            width: 130px;
            min-width: 130px;

            input {
                height: 44px;
            }
        }

        &-holder {
            display: inline-flex;
            justify-content: space-between;
            width: 47%;
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
        }

        &-currency {
            max-width: 90px;
            width: 90px;
        }

        &-payment {
            &-system {
                padding-top: 27px;
                width: 100%;
            }
        }

        .currency {
            display: block;
            min-height: 21px;
            margin-bottom: 7px;
            font-size: 14px;
            align-items: center;
            color: #212121;

            &-select {
                height: 45px;
            }
        }
    }
</style>
