import { offersList } from "@core/store/modules/admin/lists/offersList";
import { ActionTree, Module, MutationTree } from "vuex";
import { WebmastersRatesState } from "@core/store/types/admin/users/webmasters/Detail";
import { RootState } from "@core/store/root-state";
import { WebmastersDetailService } from "@core/services/admin/users/webmasters/WebmastersDetailService";
import {
  SET_WEBMASTER_RATES,
  SET_WEBMASTER_RATES_FILTERS,
  SET_WEBMASTER_RATES_PAGINATION, UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import {
  GET_WEBMASTER_RATES,
  SET_EMPTY,
  UPDATE_WEBMASTER_RATES_FILTERS,
  UPDATE_WEBMASTER_RATES_PAGINATION
} from "@core/store/action-constants";

const initialState = (): WebmastersRatesState => {
  return {
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    webmasterId: null,
    filters: {
      offer: null,
      country: null
    },
    sort: null,
    order: null
  };
};

export const state: () => WebmastersRatesState = initialState;

const mutations: MutationTree<WebmastersRatesState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [SET_WEBMASTER_RATES] (state, payload: WebmastersRatesState) {
    state.data = payload;
  },
  
  [SET_WEBMASTER_RATES_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [SET_WEBMASTER_RATES_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [UPDATE_SORTINGS] (state, sort) {
    state.sort = sort.sortField;
    state.order = sort.direction.toUpperCase();
  }
};
const actions: ActionTree<WebmastersRatesState, RootState> = {
  async [GET_WEBMASTER_RATES] ({ state, commit, rootState }) {
    const { filters: { country, ...otherFields }, pagination: { perPage, page }, sort, order } = state;
    const webmasterId = rootState.admin.users.webmasters.detail.webmasterId;
    const limit = perPage;
    const offset = (page - 1) * limit;
    const filters = { ...otherFields, country: country?.length === 0 ? null : country };
    const { data: { summaryRateByWebmaster } } = await WebmastersDetailService.getWebmasterIndividualRates(
      limit,
      offset,
      webmasterId,
      filters,
      sort,
      order
    );

    commit(SET_WEBMASTER_RATES, summaryRateByWebmaster);
  },
  
  [UPDATE_WEBMASTER_RATES_FILTERS] ({ commit }, filters) {
    commit(SET_WEBMASTER_RATES_FILTERS, filters);
  },
  
  [UPDATE_WEBMASTER_RATES_PAGINATION] ({ commit }, pagination) {
    commit(SET_WEBMASTER_RATES_PAGINATION, pagination);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit("offersList/SET_EMPTY");
  }
};

export const rates: Module<WebmastersRatesState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    offersList
  }
};