import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_ADVERTISERS_CAPACITY,
  UPDATE_CAPACITY,
  UPDATE_SUBSECTIONS
} from "@core/store/action-constants";
import {
  SET_ADVERTISERS_CAPACITY,
  SET_DISTRIBUTED_CAPACITY,
  SET_EMPTY,
  SET_FILLED_CAPACITY,
  UPDATE_FILTERS,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import {
  AdvertisersCapacityItems,
  AdvertisersCapacityState
} from "@core/store/types/admin/offers/capacity";
import { capacityHistory } from "@core/store/modules/admin/capacityAndBumps/modules/capacity/capacityHistory";
import Vue from "vue";
import { hasPermissions } from "@core/mixins/permissions";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";
import moment from "moment";
import { pagination } from "@core/helpers/pagination";

class AdvertisersCapacity implements Module<AdvertisersCapacityState, RootState> {
  public namespaced: boolean;
  public state: AdvertisersCapacityState;
  public modules: ModuleTree<RootState>;

  public getters: GetterTree<AdvertisersCapacityState, RootState> = {
    isOpenedDetails: ({ filters }) => !!(filters.webmasterId?.length && filters.offerId?.length),
  
    isShowRedemption: (state, getters, rootState, rootGetters): boolean => hasPermissions(["STATISTICS.SHOW.REDEMPTION"], rootGetters.permissions)
  }

  public mutations: MutationTree<AdvertisersCapacityState> = {
    [SET_ADVERTISERS_CAPACITY]: (state, payload): void => {
      state.data = { ...state.data, ...payload };
    },

    [UPDATE_CAPACITY]: (state, payload): void => {
      const elem = state.data?.items?.find(({ id }) => id === payload.user.id) as AdvertisersCapacityItems;
      Vue.set(elem, "softCapacity", payload.softCapacity);
      Vue.set(elem, "updatedAt", moment().toDate());
    },

    [UPDATE_SUBSECTIONS]: (state, payload): void => {
      const elem = state.data?.items?.find(({ id }) => id === payload.id) as AdvertisersCapacityItems;
      Vue.set(elem, "subsection", payload?.subsection);
    },
  
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
  
    [UPDATE_SORTINGS] (state, sort) {
      state.sort = sort.sortField;
      state.order = sort.direction.toUpperCase();
    },
  
    [SET_FILLED_CAPACITY] (state, payload) {
      const advertiser = state.data?.items?.find(({ id }) => id === payload.advertiserId) as AdvertisersCapacityItems;
      const capacity = advertiser.filledCapacity - payload.oldCapacity + payload.newCapacity;
      Vue.set(advertiser, "filledCapacity", capacity);
    },
    
    [SET_DISTRIBUTED_CAPACITY] (state, payload) {
      const advertiser = state.data?.items?.find(({ id }) => id === payload.id) as AdvertisersCapacityItems;
      const distributedCapacity = advertiser.offerAdvertiserCapacity - payload.oldCapacity + payload.newCapacity;
      Vue.set(advertiser, "offerAdvertiserCapacity", distributedCapacity);
    }
  }


  public actions: ActionTree<AdvertisersCapacityState, RootState> = {
    async [GET_ADVERTISERS_CAPACITY] ({ state, commit, getters }) {
      const { isRequest, isCommon, ...filters } = state.filters;
      const { limit, offset } = pagination();

      const advertisersCapacity = await GqlOffersService.getAdvertisersCapacity(
        limit,
        offset,
        filters,
        state.sort,
        state.order,
        isRequest ?? false,
        isCommon ?? false,
        getters.isShowRedemption
      );

      commit(SET_ADVERTISERS_CAPACITY, advertisersCapacity);
      return advertisersCapacity;
    },

    async [UPDATE_CAPACITY] ({ commit }, input) {
      const { data: { setCapacity } } = await GqlOffersService.updateCapacity(input);
      commit(UPDATE_CAPACITY, setCapacity);
    },
  
    [UPDATE_FILTERS] ({ commit }, filter) {
      commit(UPDATE_FILTERS, filter);
    },
  
    [SET_EMPTY] ({ commit }, target?: string) {
      commit(SET_EMPTY, target);
    }
  }

  private readonly initState: () => AdvertisersCapacityState;

  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): AdvertisersCapacityState => {
      return {
        data: null,
        filters: {
          isInHouse: true,
          isCommon: true,
          isLaunched: true,
          managerId: null,
          id: null,
          webmasterId: null,
          offerId: null,
          countryId: null,
          isRequest: false
        },
        sort: "yesterdayLeads",
        order: "DESC"
      };
    };

    this.mutations[SET_EMPTY] = (state, target?: keyof AdvertisersCapacityState) => {
      if (target) {
        state[target] = this.initState()[target] as keyof Nullable<AdvertisersCapacityState>;
      } else {
        Object.assign(state, this.initState());
      }
    };

    this.state = this.initState();
    this.modules = modules;

    return this;
  }
}

export const advertisersCapacity = new AdvertisersCapacity({
  capacityHistory,
  webmastersFilters: new WebmastersFilters()
});