import VueInstance from "@/main";
import momentjs from "moment";
import Vue from "vue";
import i18n from "@core/plugins/i18n";

export function momentInst (value?: Date | string, locale = i18n.locale): momentjs.Moment {
  const { offset } = VueInstance.$store.getters.timezone;
  
  return momentjs
    .utc(value)
    .utcOffset(offset)
    .locale(locale);
}

export function moment (
  value?: Date | string,
  format = "DD MMMM YYYY HH:mm",
  withOffset = false,
  dateModifier = (momentInstance: momentjs.Moment): momentjs.Moment => momentInstance,
  locale = i18n.locale
): string {
  const { offset } = VueInstance.$store.getters.timezone;
  const formatToken = withOffset ? format + offset : format;
  const momentInstance = momentInst(value, locale);
  
  return dateModifier(momentInstance).format(formatToken);
}

export function momentFrom (value?: Date | string, locale = i18n.locale): string {
  return momentjs(value).locale(locale).fromNow();
}

export function formatEmptyString (value: any, emptyString = "-"): string {
  value = value || value === 0 ? String(value).trim() : value;
  return value && value !== "Invalid date" && value !== "NaN" && !(typeof value === "number" && isNaN(value)) ?
    value : emptyString;
}

export function toFixed (value: any, digits = 0): string {
  return parseFloat(value || 0).toFixed(digits);
}

export function formatPercent (value: any): number {
  return parseFloat(value) * 100;
}

export function formatCurrency (value: any, currency = "USD"): string {
  if (value == null) {
    return formatEmptyString(value);
  }
  
  const formatter = new Intl.NumberFormat("ru-RU", { style: "currency", currency });
  return formatter.format(value);
}

Vue.filter("moment", moment);
Vue.filter("momentFrom", momentFrom);
Vue.filter("formatEmptyString", formatEmptyString);
Vue.filter("formatPercent", formatPercent);
Vue.filter("toFixed", toFixed);
Vue.filter("formatCurrency", toFixed);


