import { EfficiencyService } from "@core/services/admin/efficiency/EfficiencyService";
import { RootState } from "@core/store/root-state";
import { EfficiencyItem, EfficiencyListState } from "@core/store/types/admin/efficiency/efficiency";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  SET_EFFICIENCY_CHART_FILTERS,
  SET_EFFICIENCY_GRAPH,
  SET_EFFICIENCY_TOTAL,
  UPDATE_EFFICIENCY_FILTERS,
  UPDATE_EFFICIENCY_SORTINGS
} from "@core/store/mutation-constants";
import {
  GET_EFFICIENCY,
  SET_EMPTY,
  GET_EFFICIENCY_GRAPH,
  GET_EFFICIENCY_TOTAL,
  UPDATE_EFFICIENCY_CHART_FILTERS
} from "@core/store/action-constants";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): EfficiencyListState => {
  return {
    total: null,
    graph: null,
    chartFilters: {
      line: null,
      bar: null
    },
    filters: {
      datepicker: datepicker({ amount: 1, unit: "month" }),
      withBlockedManager: false,
      currency: null
    },
    isGridHidden: false,
    sort: null,
    order: null
  };
};

const state: () => EfficiencyListState = initialState;

const mutations: MutationTree<EfficiencyListState> = {
  [SET_EFFICIENCY_TOTAL] (state: EfficiencyListState, total): void {
    state.total = total;
  },

  [SET_EFFICIENCY_GRAPH] (state: EfficiencyListState, graph: EfficiencyListState["graph"]): void {
    state.graph = graph;
  },

  [SET_EFFICIENCY_CHART_FILTERS] (state: EfficiencyListState, chartFilters: EfficiencyListState["chartFilters"]): void {
    state.chartFilters = chartFilters;
  },

  [UPDATE_EFFICIENCY_FILTERS] (state: EfficiencyListState, filter: EfficiencyListState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_EFFICIENCY_SORTINGS] (state: EfficiencyListState, { sort, order }: { sort: string; order: string }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<EfficiencyListState, RootState> = {
  async [GET_EFFICIENCY] ({ dispatch }): Promise<void> {
    // await dispatch(GET_EFFICIENCY_GRAPH);
    await dispatch(GET_EFFICIENCY_TOTAL);
  },
  
  async [GET_EFFICIENCY_TOTAL] ({ commit, state }): Promise<void> {
    const { sort, order } = state;
    const { datepicker, currency, ...otherFields } = state.filters;
    const filters = { ...datepicker, ...otherFields };

    const total = await EfficiencyService.getEfficiency(
      currency,
      filters,
      sort,
      order
    );

    commit(SET_EFFICIENCY_TOTAL, total);
  },

  // async [GET_EFFICIENCY_GRAPH] ({ commit, state }): Promise<void> {
  //   const { filters: { datepicker, currency, ...filters }, chartFilters } = state;
  //
  //   const requestFilters = {
  //     ...datepicker,
  //     ...filters
  //   };
  //
  //   const graph = await EfficiencyService.getEfficiencyGraph(currency, requestFilters);
  //
  //   commit(SET_EFFICIENCY_GRAPH, graph);
  // },

  [UPDATE_EFFICIENCY_FILTERS] ({ commit }, filter: EfficiencyListState["filters"]): void {
    commit(UPDATE_EFFICIENCY_FILTERS, filter);
  },

  [UPDATE_EFFICIENCY_CHART_FILTERS] ({ commit }, filters: EfficiencyListState["filters"]): void {
    commit(SET_EFFICIENCY_CHART_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
  }
};

export const efficiency: Module<EfficiencyListState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
