import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { DomonetizationState } from "@core/store/types/webmaster/statistic/modules/domonetization";
import { DomonetizationGroupsEnum } from "@core/store/types/webmaster/statistic/enums/DomonetizationGroupsEnum";

export default function domonetizationStatisticsGenerator (
  limit: number,
  offset: number,
  sort: DomonetizationState["sort"],
  order: DomonetizationState["order"],
  filters: DomonetizationState["filters"],
  groups: DomonetizationGroupsEnum[],
  role: string,
  showRedemption?: boolean
): ASTGeneratedOutput {
  let include;

  if (role === "admin") {
    include = [
      "items/domonetizationWebmaster",
      "items/moneyCommission"
    ].concat(showRedemption ? [
      "items/countPayedLeads",
      "items/conversionPayout"
    ] : []);
  }

  const params = { include };

  const gqlObject = {
    operation: "domonetizationStatistics",

    fields: [
      {
        items: [
          "domonetizationOffer",
          "domonetizationCategories",
          "conversionDomonetization",
          "countAcceptedLeads",
          "moneyWebmasterReward",
          "conversionApprove",
          "countCreatedLeads",
          "offerCategories",
          "countClicks",
          "offerCategories",
          "domonetizationManager",
          "offer",
          "day",
          "week",
          "month"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      filters: {
        type: "DomonetizationStatisticsCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      sort: {
        type: "DomonetizationStatisticsSort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      },
      groups: {
        type: "[DomonetizationStatisticsGroupEnum!]!",
        value: groups
      }
    }

  };

  return Generator.query(gqlObject, params);
}