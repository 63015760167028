import { defineStore } from "pinia";
import { computed, ref, watch } from "@vue/composition-api";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { VerticalsService } from "@core/services/common/VerticalsService";
import { setPreferredVertical } from "@core/store/modules/common/helpers/setPreferredVertical";
import storeInstance from "@/store";
import router from "@/router";
import { User } from "@core/store/types/common/User";

export const useVerticals = defineStore("auth/verticals", () => {
  const permissionsStore = usePermissions();
    
  const verticals = ref<User["verticals"]>([]);
  const currentVertical = ref<VerticalsEnum | null>(null);
  const preferredVertical = ref(VerticalsEnum.NUTRA);
  
  const gamblingOnly = computed(() => permissionsStore.hasPermissions("VERTICALS.GAMBLING"));
  const nutraOnly = computed(() => permissionsStore.hasPermissions("VERTICALS.NUTRA"));
  const allVerticals = computed(() => permissionsStore.hasPermissions("VERTICALS.ALL"));
  const isVisibleByAllVerticals = computed(() => allVerticals.value || nutraOnly.value || gamblingOnly.value);
  const nutra = computed(() => allVerticals.value || nutraOnly.value);

  async function getVerticals () {
    verticals.value = await VerticalsService.getVerticals();
    // TODO: Удалить, когда перепишем модуль verticals vuex
    storeInstance.commit("verticals/SET_VERTICALS", verticals.value);
  }
  
  function updatePreferredVertical () {
    preferredVertical.value = allVerticals.value || nutraOnly.value && gamblingOnly.value
      ? preferredVertical.value : nutraOnly.value ? VerticalsEnum.NUTRA : VerticalsEnum.GAMBLING;
    setPreferredVertical(preferredVertical.value);
    // TODO: Удалить, когда перепишем модуль auth vuex
    storeInstance.commit("UPDATE_PREFERRED_VERTICAL");
  }
  
  function $reset (target?: string) {
    // TODO: Удалить, когда перепишем модуль verticals vuex
    storeInstance.commit("verticals/SET_EMPTY", target);

    switch (target) {
      case "verticals": {
        verticals.value = [];
        break;
      }
      case "currentVertical": {
        currentVertical.value = null;
        break;
      }
      case "preferredVertical": {
        preferredVertical.value = VerticalsEnum.NUTRA;
        break;
      }
      default: {
        verticals.value = [];
        currentVertical.value = null;
        preferredVertical.value = VerticalsEnum.NUTRA;
        break;
      }
    }
  }

  watch(() => router.currentRoute.name, () => {
    $reset("currentVertical");
  });

  watch(() => currentVertical.value, (value) => {
    storeInstance.commit("verticals/UPDATE_CURRENT_VERTICAL", value);
  });
  
  return {
    verticals,
    currentVertical,
    preferredVertical,
    gamblingOnly,
    nutraOnly,
    allVerticals,
    isVisibleByAllVerticals,
    nutra,
    getVerticals,
    updatePreferredVertical,
    $reset
  };
});