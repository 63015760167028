import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import {
  DashboardTopCardFilters,
  DashboardTopCardState
} from "@core/store/types/admin/lists/DashboardListState";

export default function dashboardSummary (
  limit: number,
  offset: number,
  filters: DashboardTopCardFilters["managerId"],
  periodType: DashboardTopCardFilters["periodType"],
  groupType: DashboardTopCardState["groupType"],
  sort?: DashboardTopCardState["sort"],
  order?: DashboardTopCardState["order"]
): ASTGeneratedOutput {
  const operation = {
    advertiser: "dashboardSummaryAdvertiser",
    webmaster: "dashboardSummaryWebmaster",
    offer: "dashboardSummaryOffer"
  };
  
  const gqlObject = {
    operation: operation[groupType],
    
    fields: [
      {
        items: [
          "group",
          {
            items: [
              "date",
              "countLeadsIntegrated",
              "moneyCommission",
              "offerCapacity",
              "advertiserCapacity",
              "webmasterCapacity"
            ]
          }
        ]
      },
      "count"
    ],
    
    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      filters: {
        type: "DashboardSummaryCriteriaFilters",
        value: filters
      },
      periodType: {
        type: "DashboardSummaryAdditionalGroupEnum!",
        value: periodType
      },
      sort: {
        type: "DashboardSummarySortEnum",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      }
    }
  };
  
  return Generator.query(gqlObject);
}
