import Service from "@core/services/common/Service";

type HTTPMethod = "GET" | "POST";

interface WalletRequest {
  paymentSystemId: string;
  amount: number;
}

interface WalletResponse {
  "id": number;
  "amount": number;
  "formSetting": {
    "attribute": any[];
    "link": string;
    "method": HTTPMethod;
  };
  "createdAt": string;
}

export class RefillService extends Service {
  public static getRefillInfo (payload: { currency: any }): Promise<WalletResponse> {
    return this.rest.post("/advertiser/finances/payments/order", payload);
  }
}
