import { DetailingService } from "@core/services/webmaster/statistic/detailing/DetailingService";
import { detailingStatusesList } from "@core/store/modules/webmaster/statistic/modules/lists/detailingStatusesList";
import {
  SET_DETAIL,
  SET_STATS_STATUS,
  UPDATE_FILTERS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import detailingFieldsList from "@core/store/modules/webmaster/statistic/modules/datasets/detailingFieldsList.json";
import { detailingFilters } from "@core/store/modules/webmaster/statistic/modules/detailing/detailingFilters";
import {
  DetailingState,
  LeadsFilters,
  LeadsResponse,
  LeadStatus
} from "@core/store/types/webmaster/statistic/modules/detailng";
import { ActionTree, Module, MutationTree } from "vuex";
import { UPDATE_SELECTED_LEADS } from "@core/store/action-constants";
import { detailingPostbacksResending } from "@core/store/modules/common/statistic/detailing/detailingPostbacksResending";
import { StatisticState } from "@core/store/types/common/statistic/common/statistic";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";

const modifyLeadsResponse = (leads: LeadsResponse["leads"]): DetailingState["details"] => {
  const items = leads.items.map(({ id, status, fields, ...items }) => {
    const { clickId, phone, domain, ...labels } = fields;
    const data = {
      ...items,
      id,
      clickId,
      phone,
      domain,
      status: status.map(
        (item: string) => item
          .replace(/[\s]+/gm, "_")
          .replace(/[\W\d]*/gm, "")
          .toUpperCase()
      ) as LeadStatus[]
    };

    return { data, id, labels };
  });

  const { count } = leads;
  return { items, count };
};

const initialState = (): DetailingState => {
  return {
    details: null,
    status: [],
    filters: {
      datepicker: datepicker({ amount: 1, unit: "weeks" }),
      currency: null,
      offer: null,
      flowId: null,
      status: null,
      clickId: null,
      webmaster: null,
      countries: null,
      utmCampaign: null,
      utmContent: null,
      utmMedium: null,
      utmSource: null,
      utmTerm: null
    },
    selectedLeads: [],
    selectedFields: detailingFieldsList
  };
};

const state: () => DetailingState = initialState;

const mutations: MutationTree<DetailingState> = {
  SET_DETAIL: (state, details): void => {
    state.details = details;
  },
  
  [SET_STATS_STATUS]: (state, status): void => {
    state.status = status;
  },
  
  [UPDATE_FILTERS]: (state, filters): void => {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_SELECTED_LEADS] (state, leads) {
    state.selectedLeads = leads;
  },

  [SET_EMPTY] (state, target?: keyof DetailingState) {
    if (target) {
      state[target] = initialState()[target as keyof Nullable<StatisticState>];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DetailingState, RootState> = {
  async GET_DETAIL ({ commit, state: { filters: { datepicker, ...otherFilters } }, rootGetters: { isAgent }, rootState }) {
    const { verticals: { currentVertical: vertical } } = rootState;
    const modifiedFilters: LeadsFilters = { ...datepicker, ...otherFilters, vertical } as LeadsFilters;
    const { limit, offset } = pagination(50);

    const { data: { leads } } = await DetailingService.getLeads(modifiedFilters, limit, offset, isAgent);
    const details = modifyLeadsResponse(leads);
    
    if (isAgent) {
      // @ts-ignore
      details.items = details?.items.map(item => {
        return {
          ...item,
          data: {
            ...item.data,
            commissions: {
              value: item.data.reward.webmasterReward?.value - item.data.reward.subWebmasterReward?.value,
              currency: item.data.reward.subWebmasterReward?.currency
            }
          }
        };
      });
    }
    
    commit(SET_DETAIL, details);
  },

  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
  },
  
  [UPDATE_SELECTED_LEADS] ({ commit, state }, { isChecked, dataItem }) {
    let filteredLeads = state.selectedLeads?.length ? [ ...state.selectedLeads ] : [];
    
    if (dataItem) {
      // * Toggle one
      if (isChecked) {
        filteredLeads.push(dataItem);
      } else {
        filteredLeads = filteredLeads.filter(({ data: { id } }) => id !== dataItem.data.id);
      }
    } else {
      // * Toggle all
      if (isChecked) {
        filteredLeads.push(...state.details?.items || []);
      } else {
        const itemsIds = (state.details?.items || []).map(({ data: { id } }) => id);
        filteredLeads = filteredLeads.filter(({ data: { id } }) => !itemsIds.includes(id));
      }
    }

    commit(UPDATE_SELECTED_LEADS, filteredLeads);
  },

  [SET_EMPTY] ({ commit, dispatch }, target?: string) {
    commit(SET_EMPTY, target);
    dispatch("detailingFilters/SET_EMPTY");
  }
};

export const detailing: Module<DetailingState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    detailingStatusesList,
    detailingFilters,
    detailingPostbacksResending
  }
};

