import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { OfferFilters } from "@core/store/modules/admin/statistic/modules/common/analyticOffersFilters";
import { analyticAnalyseChart } from "@core/store/modules/admin/analytic/modules/analyticAnalyseChart";
import { subAnalytic } from "@core/store/modules/admin/analytic/subanalytic";
import { RootState } from "@core/store/root-state";
import {
  AnalyticItem,
  AnalyticItems,
  AdminAnalyticState
} from "@core/store/types/admin/analytic/analytic";
import { useStringToArray } from "@core/store/modules/admin/analytic/useStringToArray";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  FILTERED_CHART_LIST,
  SET_ANALYTIC,
  SET_ANALYTIC_FILTERS,
  SET_CHART_FILTERS,
  SET_EMPTY_FILTERS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_ANALYTIC,
  SET_EMPTY,
  UPDATE_OPTIONS,
  SET_EMPTY_CONTEXT,
  UPDATE_CHART_FILTERS,
  UPDATE_ANALYTIC_FILTERS,
  SYNC_ANALYTIC_FILTERS
} from "@core/store/action-constants";
import { prepareBaseFilters } from "@core/store/modules/admin/statistic/helpers/prepareFilters";
import { analyticDetailModal } from "@core/store/modules/admin/analytic/modules/analyticDetailModal";
import { utmCampaignFilters } from "@core/store/modules/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/utmTermFilters";
import { analyticReasons } from "@core/store/modules/admin/analytic/modules/analyticReasons";
import { analyticModal } from "@core/store/modules/admin/analytic/modules/analyticModal";
import { defaultFormatter } from "@core/filters";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { domesticWebmastersList } from "@core/store/modules/admin/lists/domesticWebmastersList";
import { pagination } from "@core/helpers/pagination";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { fixedFormatPercent } from "@core/flowMethods";

const initialState = (): AdminAnalyticState => {
  return {
    analytic: null,
    filters: {
      groups: ["WEBMASTER", "DAY"],
      withBlockedWebmaster: false,
      currency: null,
      threshold: 20,
      datepicker: datepicker({ amount: 1, unit: "weeks" }),
      categoryId: null,
      webmasterId: null,
      isInHouse: null,
      advertiserId: null,
      offerId: null,
      regionId: null,
      managerId: null,
      registeredDatepicker: {
        registeredDateStart: null,
        registeredDateEnd: null
      },
      utmTerm: null,
      utmSource: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      globalSource: null,
      countryId: null,
      isDomesticWebmaster: null
    },
    chartFilters: {},
    options: {
      analysisMode: false
    }
  };
};

const state: () => AdminAnalyticState = initialState;

const mutations: MutationTree<AdminAnalyticState> = {
  [SET_ANALYTIC] (state, analytic) {
    state.analytic = analytic;
  },
  
  [SET_ANALYTIC_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [SET_CHART_FILTERS] (state, chartFilters) {
    state.chartFilters = { ...state.chartFilters, ...chartFilters };
  },

  [UPDATE_OPTIONS] (state, options) {
    state.options = { ...state.options, ...options };
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const getters: GetterTree<AdminAnalyticState, RootState> = {
  [FILTERED_CHART_LIST]: (state) => {
    const group = state.filters.groups[0];

    return state.analytic?.items.map((item: AnalyticItem) => {
      const { name, login, intId, ...fields } = useStringToArray(item.group) ?? {};
      const user = {
        ...fields,
        name: group === "WEBMASTER" ? `${ intId }` : name || login
      };
      
      const items = item.items.map((item: AnalyticItems) => ({
        leadsIntegrated: defaultFormatter(item.countLeadsIntegrated),
        leads: defaultFormatter(item.countLeadsCreated),
        validLeads: defaultFormatter(item.countLeadsCreated - item.countLeadsSpammed),
        approvePercent: defaultFormatter(item.conversionApprove * 100),
        spamPercent: fixedFormatPercent(item.countLeadsSpammed / item.countLeadsCreated),
        redemptionPercent: fixedFormatPercent(item.conversionRedemption),
        day: item.day,
        week: item.week,
        month: item.month,
        year: item.year,
        user
      }));
      
      return { user, items };
    });
  }
};

const actions: ActionTree<AdminAnalyticState, RootState> = {
  async [GET_ANALYTIC] ({ commit, state, rootState }) {
    const { limit, offset } = pagination();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { groups, currency, datepicker, registeredDatepicker, threshold, ...otherFilters } = state.filters;
    const { auth: { preferredCurrency }, verticals: { currentVertical: vertical } } = rootState;
    
    const filters = {
      ...otherFilters,
      ...datepickerFormat(datepicker, "date", true),
      ...registeredDatepicker,
      currency: currency ?? preferredCurrency,
      vertical
    };
    
    const { data: { analytic } } = await AnalyticService.getAnalytic(
      limit,
      offset,
      filters,
      groups[0],
      groups[1]
    );
  
    analytic.items = analytic.items.map((item, i) => ({ ...item, uniqueKey: new Date().getTime() + i }));
    
    commit(SET_ANALYTIC, analytic);
    commit(`subAnalytic/${ SET_EMPTY }`);
  },
  
  [UPDATE_ANALYTIC_FILTERS] ({ commit }, filters) {
    commit(SET_ANALYTIC_FILTERS, filters);
  },

  [SYNC_ANALYTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);

    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmTermFilters/${ UPDATE_FILTERS }`, baseFilters);
  },
  
  [UPDATE_CHART_FILTERS] ({ commit }, filters) {
    commit(SET_CHART_FILTERS, filters);
  },
  
  [UPDATE_OPTIONS] ({ commit }, options) {
    commit(UPDATE_OPTIONS, options);
  },

  [SET_EMPTY] ({ commit, dispatch, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    commit("offersFilters/SET_EMPTY");
    commit("analyticAnalyseChart/SET_EMPTY");
    commit("domesticWebmastersList/SET_EMPTY");
    dispatch("SET_EMPTY_COMMON");
  },
  
  [SET_EMPTY_FILTERS] ({ commit, dispatch }) {
    commit("offersFilters/SET_EMPTY");
    dispatch("SET_EMPTY_COMMON");
  },

  [SET_EMPTY_CONTEXT] ({ dispatch }) {
    dispatch("SET_EMPTY_COMMON");
  },

  SET_EMPTY_COMMON ({ commit }) {
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  }
};

export const analytic: Module<AdminAnalyticState, RootState> = {
  namespaced: true,
  modules: {
    offersFilters: new OfferFilters(),
    domesticWebmastersList,
    subAnalytic,
    utmCampaignFilters,
    utmContentFilters,
    utmMediumFilters,
    utmSourceFilters,
    utmTermFilters,
    analyticModal,
    offersList,
    analyticReasons,
    analyticDetailModal,
    analyticAnalyseChart
  },
  state,
  mutations,
  getters,
  actions
};
