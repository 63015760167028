import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function webAccuralGenerator (
  limit: number,
  offset: number,
  filters: PartialDetailingState["filters"]
): ASTGeneratedOutput {
  const money = ["value", "currency"];
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("items/lead/reward/webmasterReward/value", "items/lead/reward/webmasterReward/currency");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "webAccrual",

    fields: [
      {
        items: [
          "createdAt",
          {
            lead: [
              "id",
              {
                reward: [
                  "withdrawType",
                  "subGoalTypeEnum",
                  { commissionSystem: money },
                  { advertiserWriteOff: money },
                  { webmasterReward: money }
                ]
              },
              {
                offer: [
                  {
                    countries: [
                      "name",
                      "alpha2"
                    ]
                  },
                  {
                    detail: [
                      "name"
                    ]
                  },
                  {
                    finance: [
                      "withdrawType"
                    ]
                  }
                ]
              },
              {
                advertiser: [
                  "id",
                  "intId",
                  "login"
                ]
              },
              {
                webmaster: [
                  "id",
                  "intId",
                  "login"
                ]
              }
            ]
          }
        ]
      },
      "count",
      "aggregates"
    ],

    variables: {
      filters: {
        type: "WebAccrualListCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      }
    }

  };

  return Generator.query(gqlObject, params);
}
