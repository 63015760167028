import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { WebmasterJoinedState, WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { Money } from "@core/store/types/common";
import { OfferResponseLandingInterface } from "@core/store/types/common/offer/offer";

export class WebmasterOffer extends AbstractOffer {
  public isPostbackEnable?: boolean;
  public webmasterJoinedState?: WebmasterJoinedState;
  public isAvailLimonadDomonetization?: boolean;
  public isApiAllowed?: boolean;
  public type?: string;
  public webmasterReward?: Money;
  public currency?: string;
  public trackingUrl?: string;
  public advertiserWriteOff?: Money;
  public slug?: string;
  public competitionPoint?: number;
  public landing?: OfferResponseLandingInterface;

  constructor (payload?: WebmasterOfferInterface) {
    super(payload);
    if (payload) {
      this.isPostbackEnable = payload.isPostbackEnable;
      this.webmasterJoinedState = payload.webmasterJoinedState;
      this.isAvailLimonadDomonetization = payload?.isAvailLimonadDomonetization;
      this.isApiAllowed = payload.isApiAllowed;
      this.vertical = payload.vertical;
      this.landing = payload.landing;
      this.competitionPoint = payload.competitionPoint;
      this.trackingUrl = payload.landing?.trackingUrl;
      this.advertiserWriteOff = payload.advertiserWriteOff;
      this.type = payload.type;
      this.webmasterReward = payload.finance.webmasterReward;
      this.currency = payload.finance.currency;
      this.slug = payload.slug;
    }
  }
}

