import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { BampsAndCapsItem, BampsAndCapsState } from "@core/store/types/admin/offers/bampsAndCaps";
import {
  SET_EMPTY,
  SET_OFFER_REQUEST,
  SET_UNPROCESSED_REQUESTS,
  UPDATE_FILTERS,
  WATCHED_PARAMS,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import {
  ADD_OFFER_WEBMASTERS_AVAILABLE,
  GET_OFFER_REQUEST,
  UPDATE_OFFER_REQUEST_EFFECTIVE,
  UPDATE_OFFER_REQUEST_INEFFECTIVE,
  UPDATE_SUBSECTION_REQUEST
} from "@core/store/action-constants";
import { OfferRequestService } from "@core/services/admin/offers/OfferRequestService";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { capacityAnalyse } from "@core/store/modules/admin/capacityAndBumps/modules/log/capacityAnalyse";
import { logRequestModal } from "@core/store/modules/admin/capacityAndBumps/modules/log/logRequestModal";
import { logRequestEffectiveModal } from "@core/store/modules/admin/capacityAndBumps/modules/log/logRequestEffectiveModal";
import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";
import selectedFields from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestFields.json";
import {
  capacityRequestRatingModal
} from "@core/store/modules/admin/capacityAndBumps/modules/log/capacityRequestRatingModal";
import { hasPermissions } from "@core/mixins/permissions";
import { typeEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";

class LogRequest implements Module<BampsAndCapsState, RootState> {
  public namespaced: boolean;
  public modules: ModuleTree<RootState>;
  public state: BampsAndCapsState;
  
  public getters: GetterTree<BampsAndCapsState, RootState> = {
    [WATCHED_PARAMS]: ({ filters, sort, order }) => JSON.stringify({ filters, sort, order }),

    isShowCommission: (state, getters, rootState, rootGetters) =>
      hasPermissions(
        ["STATISTICS.SHOW_COMMISSION"],
        rootGetters.permissions
      ),

    isShowOfferAdvertisers: (state, getters, rootState, rootGetters) =>
      hasPermissions(
        ["OFFERS.VIEW_INCLUDED_ADVERTISERS.ALL", "OFFERS.VIEW_INCLUDED_ADVERTISERS.OWN"],
        rootGetters.permissions
      )
  }
  
  public mutations: MutationTree<BampsAndCapsState> = {
    [SET_OFFER_REQUEST] (state, payload) {
      state.data = { ...state.data, ...payload };
    },
  
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
  
    [UPDATE_SORTINGS] (state, { sort, order }) {
      state.sort = sort;
      state.order = order.toUpperCase();
    },

    [SET_UNPROCESSED_REQUESTS] (state, { pending: { booking, cancelCapacity, capacity, guarantee, payout } }) {
      // @ts-ignore
      state.unprocessedRequests = { booking, cancelCapacity, capacity, guarantee, payout };
    },

    ...stateUpdater.mutations
  }
  
  public actions: ActionTree<BampsAndCapsState, RootState> = {
    async [GET_OFFER_REQUEST] ({ state, commit, getters }) {
      const { datepicker, ...filters } = state.filters;
      const { limit, offset } = pagination();

      const { data: { offerParameterRequests } } = await OfferRequestService.getOfferRequest(
        limit,
        offset,
        state.sort,
        state.order,
        { ...filters, ...datepicker },
        getters.isShowCommission,
        getters.isShowOfferAdvertisers
      );

      const items = offerParameterRequests?.items?.map(el => ({
        ...el,
        advertiser: el.offer.advertisers?.items[0]?.advertiser,
        // @ts-ignore
        isCheckable: el.status === "APPROVED" && (el.type === "BOOKING" || el.type === "CAPACITY")
      }));

      commit(SET_OFFER_REQUEST, { items, count: offerParameterRequests?.count });
      commit(SET_UNPROCESSED_REQUESTS, offerParameterRequests?.aggregates);

      return offerParameterRequests;
    },
    
    async [UPDATE_OFFER_REQUEST_EFFECTIVE] ({ commit }, { id, comment }) {
      const { data: { markOfferParameterRequestAsEffective } } = await OfferRequestService.updateOfferRequestEffective(id, comment);
  
      commit("LOCAL_UPDATE", { items: markOfferParameterRequestAsEffective, target: "data" });
      
      return markOfferParameterRequestAsEffective;
    },
    
    async [UPDATE_OFFER_REQUEST_INEFFECTIVE] ({ commit }, { id, comment }) {
      const { data: { markOfferParameterRequestAsIneffective } } = await OfferRequestService.updateOfferRequestIneffective(id, comment);
  
      commit("LOCAL_UPDATE", { items: markOfferParameterRequestAsIneffective, target: "data" });
      
      return markOfferParameterRequestAsIneffective;
    },
  
    async [ADD_OFFER_WEBMASTERS_AVAILABLE] (_, { offerId, webmasterId }) {
      if (offerId) {
        await OfferEditWebmastersServices.offerIncludeMultipleWebmasters(offerId, [{ id: webmasterId }]);
      }
    },
  
    [UPDATE_FILTERS] ({ commit }, payload) {
      commit(UPDATE_FILTERS, payload);
    },
  
    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
    },
    
    [UPDATE_SUBSECTION_REQUEST] ({ commit }, payload) {
      // const dataSet = new Proxy({
      //   [statusEnum.PENDING]: "LOCAL_ADD",
      //   [statusEnum.DELETED]: "LOCAL_DELETE"
      // }, {
      //   get: (obj, key: string) => key in obj ? obj[key] : "LOCAL_UPDATE"
      // });
      // commit(dataSet[payload.data.status], { items: payload.data, target: "data" });
    },
  
    [SET_EMPTY] ({ commit }, target?: string) {
      commit(SET_EMPTY, target);
    }
  }
  
  private readonly initState: () => BampsAndCapsState;
  
  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): BampsAndCapsState => {
      return {
        data: null,
        filters: {
          datepicker: datepicker({ amount: 1, unit: "weeks" }),
          advertiser: null,
          executor: null,
          creator: null,
          offer: null,
          result: null,
          type: typeEnum.PAYOUT,
          inHouse: null,
          status: null,
          webmaster: null,
          country: null
        },
        unprocessedRequests: null,
        selectedFields: selectedFields,
        sort: null,
        order: null
      };
    };
  
    this.mutations[SET_EMPTY] = (state, target?: keyof BampsAndCapsState) => {
      if (target) {
        state[target] = this.initState()[target] as keyof Nullable<BampsAndCapsState>;
      } else {
        Object.assign(state, this.initState());
      }
    };
    
    this.state = this.initState();
    this.modules = modules;
    
    return this;
  }
}

export const logRequest = new LogRequest({
  capacityAnalyse,
  logRequestModal,
  logRequestEffectiveModal,
  capacityRequestRatingModal
});