import {
  LoginAsAdministratorResponse,
  LoginAsAdvertiserResponse,
  LoginAsWebmasterResponse,
  TelegramLogin,
  RefreshToken,
  WhoAmI,
  Login,
  Auth
} from "@core/store/types/common/auth";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import authentication from "./graphql/authentication.graphql";
import logoutProfileSession from "./graphql/logoutProfileSession.graphql";
import authenticationConfirm from "./graphql/authenticationConfirm.graphql";
import authenticationByTelegram from "./graphql/authenticationByTelegram.graphql";
import authenticationLogout from "./graphql/authenticationLogout.graphql";
import returnToAdminProfile from "./graphql/returnToAdminProfile.graphql";
import loginAsAdministrator from "./graphql/loginAsAdministrator.graphql";
import loginAsAdvertiser from "./graphql/loginAsAdvertiser.graphql";
import loginAsWebmaster from "./graphql/loginAsWebmaster.graphql";
import refreshToken from "./graphql/refreshToken.graphql";
import whoAmI from "./graphql/whoAmI.generator";
import setYandexClientId from "./graphql/setYandexClientId.graphql";
import userPermissions from "./graphql/userPermissions.graphql";

export class AuthenticationService extends Service {
  public static async login (input: Login): Promise<Auth> {
    try {
      const { data: { authentication: data } } = await this.api.post("", {
        query: authentication,
        variables: { input }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async whoAmI (role: Nullable<string>): Promise<WhoAmI> {
    try {
      const { data: { whoAmI: data } } = await this.api.get("", {
        params: whoAmI(role)
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async authConfirm (loginId: string): Promise<any> {
    try {
      const { data: { authenticationConfirm: data } } = await this.api.post("", {
        query: authenticationConfirm,
        variables: { loginId }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async loginByTelegram (input: TelegramLogin): Promise<Auth> {
    try {
      const { data: { authenticationByTelegram: data } } = await this.api.post("", {
        query: authenticationByTelegram,
        variables: { input }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async returnToAdminProfile (): Promise<Auth> {
    try {
      const { data: { returnToAdminProfile: data } } = await this.api.post("", {
        query: returnToAdminProfile
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async authenticationLogout (): Promise<Auth> {
    try {
      const { data: { authenticationLogout: data } } = await this.api.post("", {
        query: authenticationLogout
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async setYandexClientId (clientId: string): Promise<void> {
    try {
      return await this.api.get("", {
        params: {
          query: setYandexClientId,
          variables: { clientId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async refreshToken (input: RefreshToken, headerOptions: string) {
    try {
      const { data: { refreshToken: data } } = await this.api.post("",
        {
          query: refreshToken,
          variables: { input }
        },
        {
          headers: {
            "X-Security-Options": headerOptions
          }
        });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static logoutProfileSession (loginId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: logoutProfileSession,
        variables: {
          loginId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static loginAsAdministrator (targetAdminId: string): AxiosPromise<LoginAsAdministratorResponse> {
    try {
      return this.api.post("", {
        query: loginAsAdministrator,
        variables: {
          input: { targetAdminId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static loginAsWebmaster (webmasterId: string): AxiosPromise<LoginAsWebmasterResponse> {
    try {
      return this.api.post("", {
        query: loginAsWebmaster,
        variables: {
          input: { webmasterId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static loginAsAdvertiser (advertiserId: string): AxiosPromise<LoginAsAdvertiserResponse> {
    try {
      return this.api.post("", {
        query: loginAsAdvertiser,
        variables: {
          input: { advertiserId }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getUserPermissions (): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: userPermissions
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
