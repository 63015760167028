var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{ref:"vSelect",class:['control', _vm.inputClass, {
        'fixed': !_vm.multiple,
        'not-empty': _vm.value != null && _vm.value.length !== 0, 'fix-height': _vm.isFixHeight
    }],attrs:{"append-to-body":_vm.appendToBody && !_vm.multiple,"autocomplete":_vm.autocomplete,"calculate-position":_vm.calculatePosition,"clearable":_vm.isClearable,"close-on-select":!_vm.multiple,"create-option":_vm.createOption,"disabled":_vm.disabled || _vm.isInitialDataLoading,"filter-by":_vm._filterBy,"filterable":_vm.filterable,"label":_vm.field,"loading":_vm.loading || _vm.isInitialDataLoading,"multiple":_vm.multiple,"no-drop":_vm.noDrop,"options":_vm.filteredOptions,"placeholder":_vm.placeholder,"taggable":_vm.taggable,"value":_vm.computedValue,"selectable":_vm.selectable},on:{"close":_vm.close,"input":function($event){return _vm.select($event)},"open":_vm.open,"search":_vm.debouncedSearch,"option:selecting":_vm.selectOption,"search:blur":_vm.selectingOnBlur},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.selectingOnSpace.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.hasNextPage || _vm.localLoading)?{key:"list-footer",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('InlineLoader',{ref:"load",attrs:{"active":"","size":"is-large"}})],1)]},proxy:true}:null,{key:"search",fn:function(ref){
    var attributes = ref.attributes;
    var events = ref.events;
return [_vm._t("search",function(){return [_c('input',_vm._g(_vm._b({ref:"input",class:[{ 'mt-0': !_vm.computedValue },'vs__search'],style:({
                    cursor: _vm.disabled ? 'not-allowed' : !_vm.searchable ? 'pointer' : 'text',
                    'caret-color': !_vm.searchable ? 'transparent' : 'auto'
                }),attrs:{"required":_vm.required && !_vm.computedValue}},'input',attributes,false),events))]},{"attributes":attributes,"computedValue":_vm.computedValue,"cssClass":[{ 'mt-0': !_vm.computedValue },'vs__search'],"events":events,"required":_vm.required})]}},{key:"option",fn:function(option){return [_vm._t("default",function(){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._t("text",function(){return [_c('p',{class:[{ 'marquee': _vm.marquee && ((_vm.field ? option[_vm.field] : option) || option.id).length > _vm.maxChars }, 'nowrap']},[_vm._v(" "+_vm._s((_vm.field ? option[_vm.field] : option) || option.id)+" ")])]},{"field":_vm.field,"option":option})],2)])]},{"option":option})]}},{key:"selected-option",fn:function(option){return [_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s((_vm.field ? option[_vm.field] : option) || option.id)+" ")]},{"option":option})]}},{key:"selected-option-container",fn:function(options){return [_vm._t("option-container",null,null,options)]}},{key:"open-indicator",fn:function(ref){
                var attributes = ref.attributes;
return [(_vm.indicator)?_c('b-icon',_vm._b({attrs:{"icon":"angle-down","pack":"fas","size":"is-small"}},'b-icon',attributes,false)):_c('span')]}},{key:"spinner",fn:function(ref){
                var loading = ref.loading;
return [(loading)?_c('div',{staticClass:"vs__spinner"}):_vm._e()]}},{key:"no-options",fn:function(){return [_c('div')]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }