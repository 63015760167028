import { PaymentSystemsListService } from "@core/services/common/lists/PaymentSystemsListService";
import {
  GET_INITIAL_PAYMENT_SYSTEMS,
  GET_PAYMENT_SYSTEMS,
  UPDATE_PAYMENT_SYSTEMS_LIST
} from "@core/store/action-constants";
import {
  EMPTY_PAYMENT_SYSTEMS_LIST,
  UPDATE_PAYMENT_SYSTEMS_FILTERS,
  UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  PartialPaymentSystemsListState,
  PaymentSystemsListResponse
} from "@core/store/types/common/lists/PaymentSystemsListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialPaymentSystemsListState => {
  return {
    data: {},
    filters: {
      id: null,
      withAttachedToUser: null
    },
    pagination: {
      page: 1,
      perPage: 30
    }
  };
};

const state: () => PartialPaymentSystemsListState = initialState;

const getters: GetterTree<PartialPaymentSystemsListState, RootState> = {};

const mutations: MutationTree<PartialPaymentSystemsListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_PAYMENT_SYSTEMS_LIST] (state, paymentSystems: PaymentSystemsListResponse["paymentSystems"]) {
    if (state.pagination.page !== 1 && state.data?.items && paymentSystems) {
      state.data.items = Array.from(new Set([...state.data.items, ...paymentSystems.items as []]));
    } else {
      state.data = paymentSystems;
    }
  },

  [UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_PAYMENT_SYSTEMS_FILTERS] (state, filters: PartialPaymentSystemsListState["filters"]) {
    if (filters?.name !== state.filters?.name) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_PAYMENT_SYSTEMS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialPaymentSystemsListState, RootState> = {
  async [GET_PAYMENT_SYSTEMS] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = state.filters?.name ? 0 : (state.pagination.page - 1) * limit;
    const { data: { paymentSystems } } =
      await PaymentSystemsListService.getPaymentSystems(state.filters, limit, offset);
    commit(UPDATE_PAYMENT_SYSTEMS_LIST, paymentSystems);
    return paymentSystems;
  },

  async [GET_INITIAL_PAYMENT_SYSTEMS] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const { data: { paymentSystems, initialPaymentSystems } } = await PaymentSystemsListService.getPaymentSystems(
      state.filters,
      state.pagination.perPage,
      0,
      { id: ids });
    paymentSystems.items = [...initialPaymentSystems.items, ...paymentSystems.items as []];

    commit(UPDATE_PAYMENT_SYSTEMS_LIST, paymentSystems);
    return initialPaymentSystems?.items?.length ? initialPaymentSystems.items.map(user => user.id) : null;
  }
};

export const paymentSystemsList: Module<PartialPaymentSystemsListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
