import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import deletePage from "./graphql/deletePage.graphql";

export class OfferLandingsService extends Service {
  public static removePage (pageId: string): AxiosPromise {
    return this.api.post("", {
      query: deletePage,
      variables: { pageId }
    });
  }
}
