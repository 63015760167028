import { AdministratorsService } from "@core/services/admin/users/administrators/AdministratorsService";
import { hasPermissions } from "@core/mixins/permissions";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import {
  BLOCK_ADMINISTRATOR,
  GET_ADMINISTRATORS,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_ADMINISTRATOR
} from "@core/store/action-constants";
import {
  SET_ADMINISTRATORS, SET_EMPTY,
  UPDATE_ADMINISTRATORS_FILTERS,
  UPDATE_ADMINISTRATORS_PAGINATION,
  UPDATE_ADMINISTRATORS_SORTS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdministratorsState } from "@core/store/types/admin/users/administrators/AdministratorsState";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { ActionTree, Module, MutationTree } from "vuex";
import { edit } from "./modules/edit";
import { rolesList } from "@core/store/modules/common/lists/rolesList";
import { pagination } from "@core/helpers/pagination";

const initialState = (): AdministratorsState => {
  return {
    administrators: null,
    filters: {
      loginOrIntId: null,
      isBlocked: false,
      email: null,
      skype: null,
      telegram: null,
      permission: null,
      countryOfManager: null
    },
    sort: null,
    order: null
  };
};

const state: () => AdministratorsState = initialState;

const mutations: MutationTree<AdministratorsState> = {
  [SET_ADMINISTRATORS]: (state, administrators: AdministratorsState["administrators"]): void => {
    state.administrators = administrators;
  },

  [UPDATE_ADMINISTRATORS_FILTERS] (state, filter: AdministratorsState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_ADMINISTRATORS_SORTS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [SET_EMPTY] (state, target?: keyof AdministratorsState) {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<AdministratorsState, RootState> = {
  async [GET_ADMINISTRATORS] ({ commit, state, rootGetters }) {
    const { limit, offset } = pagination();

    const hasPermissionsAdvertiser = hasPermissions(["ADMIN.ADD_OR_EDIT_PERMISSION"], rootGetters.permissions);

    try {
      const { data: { admins } } = await AdministratorsService.getAdministrators(
        state.filters,
        limit,
        offset,
        state.sort,
        state.order,
        hasPermissionsAdvertiser
      );

      commit(SET_ADMINISTRATORS, admins);
  
    } catch (error) {
      throw error;
    }
  },

  [UPDATE_ADMINISTRATORS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_ADMINISTRATORS_FILTERS, filter);
  },

  [UPDATE_ADMINISTRATORS_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_ADMINISTRATORS_PAGINATION, pagination);
  },
  
  async [BLOCK_ADMINISTRATOR] ({ commit }, administratorId: string) {
    const blockedAdmin = await AdministratorsService.blockAdministrator(administratorId);
    commit("LOCAL_UPDATE", { items: blockedAdmin, target: "administrators" });
  },
  
  async [UNBLOCK_ADMINISTRATOR] ({ commit }, administratorId: string) {
    const unBlockedAdmin = await AdministratorsService.unBlockAdministrator(administratorId);
    commit("LOCAL_UPDATE", { items: unBlockedAdmin, target: "administrators" });
  },

  async [RESEND_EMAIL_BY_ADMIN] (_: unknown, userId: string) {
    await UsersService.resendEmailByAdmin(userId);
  },
  
  [SET_EMPTY] ({ commit }, target?: string) {
    commit(SET_EMPTY, target);
  }
};

export const administrators: Module<AdministratorsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    edit,
    rolesList
  }
};
