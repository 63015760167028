import { rates } from "./modules/rates";
import { ActionTree, Module, MutationTree } from "vuex";
import { WebmastersDetailState } from "@core/store/types/admin/users/webmasters/Detail";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY, SET_WEBMASTER_ID } from "@core/store/mutation-constants";
import { UPDATE_WEBMASTER_ID } from "@core/store/action-constants";

const initialState = (): WebmastersDetailState => {
  return {
    webmasterId: null
  };
};

export const state: () => WebmastersDetailState = initialState;

const mutations: MutationTree<WebmastersDetailState> = {
  [SET_WEBMASTER_ID] (state, payload: string){
    state.webmasterId = payload;
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<WebmastersDetailState, RootState> = {
  [UPDATE_WEBMASTER_ID] ({ commit }, webmasterId){
    commit(SET_WEBMASTER_ID, webmasterId);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit(`rates/${ SET_EMPTY }`);
    commit(`offersList/${ SET_EMPTY }`);
  }
};

export const detail: Module<WebmastersDetailState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    rates
  }
};