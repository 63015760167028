import { defineStore } from "pinia";
import { ref } from "@vue/composition-api";
import AuthenticationProvider from "@core/logic/auth/AuthenticationProvider";
import storeInstance from "@/store";
import { useVerticals } from "@/stores/common/auth/verticalsStore";

export const usePermissions = defineStore("auth/permissions", () => {
  const verticalsStore = useVerticals();
  const userPermissions = ref<string[] | null>(null);

  const arrify = (a: string[] | string) => Array.isArray(a) ? a : [a];
  const isAllow = (A: string[], B: string[]) => A.some(el => B?.includes(el));
  const isAllowStrict = (A: string[], B: string[]) => A.every(el => B?.includes(el));

  async function GET_USER_PERMISSIONS () {
    if (storeInstance.state.auth.role !== "admin")
      return;

    const { userPermissions: data } = await AuthenticationProvider.getUserPermissions();
    userPermissions.value = data;
    // TODO: Удалить, когда перепишем модуль auth vuex
    storeInstance.commit("GET_USER_PERMISSIONS", data);
    verticalsStore.updatePreferredVertical();
  }
  
  function hasPermissions (permissions: string[] | string) {
    if (
      permissions && permissions.includes("ANY") ||
          permissions && permissions.includes("SUPER.PERMISSION") &&
          userPermissions.value && userPermissions.value.includes("SUPER.PERMISSION")
    ) {
      return true;
    }

    return !(
      Array.isArray(permissions) && permissions.length === 0 ||
          ["", 0, null, NaN, false].includes(permissions as string) ||
          permissions && userPermissions &&
          !isAllow(arrify(permissions), userPermissions.value!)
    );
  }

  function hasPermissionsStrict (permissions: string[] | string) {
    if (
      permissions && permissions.includes("ANY") ||
            permissions && permissions.includes("SUPER.PERMISSION") &&
            userPermissions.value && userPermissions.value.includes("SUPER.PERMISSION")
    ) {
      return true;
    }

    return !(
      Array.isArray(permissions) && permissions.length === 0 ||
            ["", 0, null, NaN, false].includes(permissions as string) ||
            permissions && userPermissions &&
            !isAllowStrict(arrify(permissions), userPermissions.value!)
    );
  }

  function $reset () {
    userPermissions.value = [];
  }

  return {
    userPermissions,
    GET_USER_PERMISSIONS,
    hasPermissions,
    hasPermissionsStrict,
    $reset
  };
});