import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { LeadsFilters } from "@core/store/types/webmaster/statistic/modules/detailng";

export default function leadsGenerator (
  filters: LeadsFilters,
  limit: number,
  offset: number,
  isAgent: boolean
): ASTGeneratedOutput {
  const { vertical } = filters;
  const agent = isAgent ? [
    "items/webmaster/login",
    "items/webmaster/intId",
    "items/webmaster/id",
    "items/reward/subWebmasterReward/value",
    "items/reward/subWebmasterReward/currency"
  ] : [];
  const dataSet = {
    NUTRA: [
      ...agent,
      "items/fields/phone",
      "items/callsCount",
      "items/cancelReason",
      "items/spamReason",
      "items/comment"
    ],
    GAMBLING: [
      ...agent,
      "items/firstDeposit/date",
      "items/firstDeposit/amount",
      "items/summaryDeposit/count",
      "items/summaryDeposit/total"
    ]
  };
  const params = {
    // @ts-ignore
    include: dataSet[vertical]
  };
  
  const gqlObject = {
    operation: "leads",
    
    fields: [
      {
        items: [
          "createdAt",
          "id",
          "status",
          {
            fields: [
              "clickId",
              "utm_campaign",
              "utm_content",
              "utm_medium",
              "utm_source",
              "utm_term"
            ]
          },
          {
            reward: [
              "unholdDate",
              {
                webmasterReward: [
                  "value",
                  "currency"
                ]
              }
            ]
          },
          {
            country: [
              "id",
              "alpha2",
              "name"
            ]
          },
          {
            flow: [
              "name"
            ]
          },
          {
            offer: [
              {
                detail: [
                  "name"
                ]
              },
              "id",
              "intId"
            ]
          }
        ]
      },
      "count"
    ],
    
    variables: {
      filters: {
        type: "LeadListCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      }
    }
    
  };
  
  return Generator.query(gqlObject, params);
}
