import { DomonetizationService } from "@core/services/common/statistic/domonetization/DomonetizationService";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_DOMONETIZATION,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  SET_DOMONETIZATION,
  UPDATE_SORTINGS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { DomonetizationState } from "@core/store/types/admin/statistic/domonetization/domonetization";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import { hasPermissions } from "@core/mixins/permissions";
import { DomonetizationGroupsEnum } from "@core/store/types/webmaster/statistic/enums/DomonetizationGroupsEnum";

const initialState = (): DomonetizationState => {
  return {
    domonetization: null,
    filters: {
      domonetizationAlias: null,
      domonetizationOfferId: null,
      domonetizationWebmasterId: null,
      offerId: null,
      datepicker: datepicker({ amount: 1, unit: "months" }),
      groups: [DomonetizationGroupsEnum.DOMONETIZATION_OFFER, null]
    },
    sort: null,
    order: null
  };
};

const state: () => DomonetizationState = initialState;

const getters: GetterTree<DomonetizationState, RootState> = {};

const mutations: MutationTree<DomonetizationState> = {
  [SET_DOMONETIZATION] (state: DomonetizationState, items: DomonetizationState["domonetization"]): void {
    state.domonetization = items;
  },
  
  [UPDATE_FILTERS] (state: DomonetizationState, filters: DomonetizationState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_SORTINGS] (state: DomonetizationState, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [SET_EMPTY] (state, target?: DomonetizationState): void {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DomonetizationState, RootState> = {
  async [GET_DOMONETIZATION] ({ commit, rootGetters, state }): Promise<void> {
    const { filters: { datepicker, groups, ...fields }, sort, order } = state;
    const filters = { ...fields, ...datepicker };
    const { limit, offset } = pagination();
    const showRedemption = hasPermissions(["STATISTICS.SHOW.REDEMPTION"], rootGetters.permissions);

    const data = await DomonetizationService.getDomonetization(limit, offset, sort, order, filters, groups.filter(Boolean), rootGetters.role, showRedemption);
    commit(SET_DOMONETIZATION, data);
  },
  
  [UPDATE_FILTERS] ({ commit }, filters: DomonetizationState["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },
  
  [UPDATE_SORTINGS] ({ commit, dispatch }, payload) {
    commit(UPDATE_SORTINGS, payload);
    dispatch(GET_DOMONETIZATION);
  },
  
  [SET_EMPTY] ({ commit }, target?: string): void {
    commit("SET_EMPTY", target);
  }
};

export const domonetization: Module< DomonetizationState, RootState> = {
  namespaced: true,
  modules: {},
  state,
  mutations,
  getters,
  actions
};
