
























import { defineProps, defineEmits, defineOptions } from "@vue/runtime-dom";
import { computed, ref, useAttrs } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: null,
    required: true
  },
  icon: {
    type: String,
    default: null
  },
  iconRight: {
    type: String,
    default: null
  },
  iconPack: {
    type: String,
    default: "fas"
  },
  lazy: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  defineOptions({
    inheritAttrs: false
  });
  const emit = __ctx.emit;
  const attrs = useAttrs();
  const isValid = ref<boolean>(true);
  const isPassword = computed(() => attrs.type === "password");
  function update(value: string) {
    emit("input", value === "" ? null : value);
  }
  function blur(event: any) {
    isValid.value = event.target.validity.valid;
    emit("blur", event);
  }
  return {
    emit,
    isPassword,
    update,
    blur
  };
};
export default __sfc_main;
