import {
  UPDATE_MODAL_ACTIVE,
  SET_EMPTY,
  FILTERED_ACTIONS_LIST,
  FILTERED_TARGETED_LEADS,
  FILTERED_TARGETED_APPROVES,
  FILTERED_TARGETED_SPAMS,
  TARGETED_ACTIONS_LIST, UPDATE_ACTIONS_LIST,
  FILTERED_TARGETED_REDEMPTION
} from "@core/store/mutation-constants";
import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import targetedLeads from "../modules/datasets/targetedLeads.json";
import targetedApproves from "../modules/datasets/targetedApproves.json";
import targetedSpams from "../modules/datasets/targetedSpams.json";
import targetedRedemption from "../modules/datasets/targetedRedemption.json";

import {
  ActionsList,
  AnalyticModal,
  TargetedList,
  TargetedActionsList
} from "@core/store/types/admin/analytic/analyticModal";
import { mixPermissions } from "@core/helpers/mixPermissions";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const initialState = (): AnalyticModal => {
  const actionsList = localStorage.getItem("targetedActions");
  
  // @ts-ignore
  return {
    isModalActive: false,
    targetedLeads,
    targetedApproves,
    targetedSpams,
    targetedRedemption,
    actionsList: actionsList ? JSON.parse(actionsList) : {
      leads: ["leadsValidAccepted", "leads"],
      approves: ["commonApprove", "cleanApprove"],
      spams: ["leadsTrash"],
      redemption: ["redemption"],
      capacityIndicator: true,
      guaranteeRate: true
    }
  };
};

const state: () => AnalyticModal = initialState;

function filteringOnInvalidActions (target: TargetedList[]) {
  return (item: string) => target.some((t) => t.value === item);
}

function filterForVerticals (fields: TargetedList[], currentVertical: VerticalsEnum) {
  return fields.filter(field => field.visible.includes(currentVertical));
}


const getters: GetterTree<AnalyticModal, RootState> = {
  [FILTERED_ACTIONS_LIST]: (state, getters, { verticals: { currentVertical } }) => {
    const leads = state.actionsList.leads
      ?.filter(filteringOnInvalidActions(filterForVerticals(state.targetedLeads, currentVertical))); // Удаляет старые неиспользуемые значения в селектах
    const approves = state.actionsList.approves
      ?.filter(filteringOnInvalidActions(filterForVerticals(state.targetedApproves, currentVertical)));
    const spams = state.actionsList.spams
      ?.filter(filteringOnInvalidActions(filterForVerticals(state.targetedSpams, currentVertical)));
    const redemption = mixPermissions(state.actionsList.redemption
      ?.filter(filteringOnInvalidActions(filterForVerticals(state.targetedRedemption, currentVertical))), [
      {
        name: "redemption",
        permissions: ["STATISTICS.SHOW.REDEMPTION"]
      }
    ]);
    const capacityIndicator = state.actionsList.capacityIndicator;
    const guaranteeRate = state.actionsList.guaranteeRate;

    return {
      leads,
      approves,
      capacityIndicator,
      guaranteeRate,
      spams,
      redemption
    };
  },
  
  [FILTERED_TARGETED_LEADS]: (state, getters, { verticals }) => filterForVerticals(state.targetedLeads, verticals.currentVertical),
  
  [FILTERED_TARGETED_APPROVES]: (state, getters, { verticals }) => filterForVerticals(state.targetedApproves, verticals.currentVertical),
  
  [FILTERED_TARGETED_SPAMS]: (state, getters, { verticals }) => filterForVerticals(state.targetedSpams, verticals.currentVertical),
  
  [FILTERED_TARGETED_REDEMPTION]: (state, getters, { verticals }) =>
    mixPermissions(filterForVerticals(state.targetedRedemption, verticals.currentVertical), [
      {
        name: "redemption",
        permissions: ["STATISTICS.SHOW.REDEMPTION"]
      }
    ]),
  
  [TARGETED_ACTIONS_LIST]: (state, getters) => function (localActionsList: ActionsList): TargetedActionsList {
    // Конвертируем в объект со всеми ключами и значениями false
    const allPossibleActions = Object.fromEntries([
      ...state.targetedLeads.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedApproves.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedSpams.map((item: TargetedList) => [ item.value, false ]),
      ...state.targetedRedemption.map((item: TargetedList) => [ item.value, false ])
    ]);
  
    // Меняем значения ключей содержащихся в массивах localStorage или селектов модального окна
    Object.entries(localActionsList ?? getters.FILTERED_ACTIONS_LIST).forEach(([, value]: [string, string[]]) => {
      // Фильтруем массив по правам (если нет права, то убираем из массива)
      if (Array.isArray(value)){
        const filteredPermissionsValue = mixPermissions(value, [
          {
            name: "redemption",
            permissions: ["STATISTICS.SHOW.REDEMPTION"]
          }
        ]);
        filteredPermissionsValue?.forEach((item: string) => {
          allPossibleActions[item] = true;
        });
      }
      allPossibleActions.capacityIndicator = localActionsList?.capacityIndicator ?? state.actionsList.capacityIndicator;
      allPossibleActions.guaranteeRate = localActionsList?.guaranteeRate ?? state.actionsList.guaranteeRate;
    });
    
    return allPossibleActions;
  }
};

const mutations: MutationTree<AnalyticModal> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_ACTIVE] (state, payload) {
    state.isModalActive = payload;
  },
  
  [UPDATE_ACTIONS_LIST] (state, payload) {
    localStorage.setItem("targetedActions", JSON.stringify(payload));
  }
};

export const analyticModal: Module<AnalyticModal, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
