import { Filters } from "../../../common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  OfferFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import {
  CapacityAnalyseExternalWebmastersListService
} from "@core/services/admin/capacityAndBumps/common/capacityAnalyseExternalWebmastersListService";
import { ExternalWebmasterItem } from "@core/store/types/admin/statistic/common/externalWebmasterFilters";

export class CapacityAnalyseExternalWebmastersList extends Filters<ExternalWebmasterItem, BaseFiltersInput> {
  // @ts-ignore
  getData = async (offset: number, limit: number, filters: OfferFiltersInput) => {
    // @ts-ignore
    const { offerId, webmasterId, excludedFilterOptions, ...otherFilters } = filters;

    const { data: { capacityAnalyseExternalWebmastersList: data } } = await CapacityAnalyseExternalWebmastersListService.getExternalWebmastersList(
      // @ts-ignore
      offerId,
      webmasterId,
      { ...otherFilters },
      excludedFilterOptions,
      limit,
      offset
    );

    const dataItems = data.items?.map(item => ({ label: item, value: item }));

    return {
      data: {
        items: dataItems,
        count: data.count
      },
      initialData: {
        items: [],
        count: 0
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
