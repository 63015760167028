import Service from "@core/services/common/Service";
import { AddPayment, Payment } from "@core/store/types/admin/payments/Payments";
import addPaymentSystemQuery from "./graphql/addPaymentSystem.graphql";
import deletePaymentSystemQuery from "./graphql/deletePaymentSystem.graphql";
import paymentSystemsQuery from "./graphql/paymentSystems.graphql";
import updatePaymentSystemQuery from "./graphql/updatePaymentSystem.graphql";
import disablePaymentSystemQuery from "./graphql/disablePaymentSystem.graphql";
import enablePaymentSystemQuery from "./graphql/enablePaymentSystem.graphql";

export class PaymentsService extends Service {
  /**
   * Метод получения списка платежных систем
   * @param {number} limit
   * @param {number} offset
   * @return {Promise<Payment[]>}
   */
  public static async getPaymentSystems (
    limit: number,
    offset: number
  ): Promise<Payment[]> {
    try {
      const { data: { paymentSystems } } = await this.api.get("", {
        params: {
          query: paymentSystemsQuery,
          variables: {
            limit,
            offset
          }
        }
      });
      return paymentSystems;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод добавления платежной системы
   * @param {NullableRecord<AddPayment>} payload
   * @return {Promise<Payment[]>}
   */
  public static async addPaymentSystem (
    payload: NullableRecord<AddPayment>
  ): Promise<Payment[]> {
    try {
      const { data: { addPaymentSystem } } = await this.api.post("", {
        query: addPaymentSystemQuery,
        variables: {
          input: { ...payload }
        }
      });
      return addPaymentSystem;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод изменения платежной системы
   * @param {string} paymentSystemId
   * @param {NullableRecord<AddPayment>} payload
   * @return {Promise<Payment[]>}
   */
  public static async updatePaymentSystem (
    paymentSystemId: string,
    payload: NullableRecord<AddPayment>
  ): Promise<Payment[]> {
    try {
      const { data: { updatePaymentSystem } } = await this.api.post("", {
        query: updatePaymentSystemQuery,
        variables: {
          paymentSystemId,
          input: {
            ...payload
          }
        }
      });
      return updatePaymentSystem;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод удаления платежной системы
   * @param {string} id
   * @return {Promise<Payment[]>}
   */
  public static async deletePaymentSystem (
    id: string
  ): Promise<Payment[]> {
    try {
      const { data: { deletePaymentSystem } } = await this.api.post("", {
        query: deletePaymentSystemQuery,
        variables: {
          id
        }
      });
      return deletePaymentSystem;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод запуска платежной системы
   * @param {string} paymentSystemId
   * @return {Promise<Payment[]>}
   */
  public static async enablePaymentSystem (
    paymentSystemId: string
  ): Promise<Payment[]> {
    try {
      const { data: { enablePaymentSystem } } = await this.api.post("", {
        query: enablePaymentSystemQuery,
        variables: {
          paymentSystemId
        }
      });
      return enablePaymentSystem;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод остановки платежной системы
   * @param {string} paymentSystemId
   * @return {Promise<Payment[]>}
   */
  public static async disablePaymentSystem (
    paymentSystemId: string
  ): Promise<Payment[]> {
    try {
      const { data: { disablePaymentSystem } } = await this.api.post("", {
        query: disablePaymentSystemQuery,
        variables: {
          paymentSystemId
        }
      });
      return disablePaymentSystem;
    } catch (e) {
      throw e;
    }
  }
}
